import React, {DetailedHTMLProps, FC, HTMLAttributes, useState} from "react";
import {IconButton, Menu, MenuItem, Typography} from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import {logout} from "../../../redux/slices/auth";
import {useAppDispatch} from "../../../hooks/useAppDispatch";
import {useNavigate} from "react-router-dom";
import {useGetMeQuery} from "../../../redux/api/user";

export type UserMenuProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const UserMenu: FC<UserMenuProps> = (props) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const {data: currentUser, isSuccess} = useGetMeQuery();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div {...props}>
            <IconButton
                id="user-button"
                edge="end"
                aria-controls="user-menu"
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                size="large"
                onClick={handleClick}
                color="inherit"
            >
                <AccountCircleOutlinedIcon />
                {isSuccess && (<Typography>{currentUser!.givenName} {currentUser!.familyName}</Typography>)}
            </IconButton>
            <Menu
                id="user-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'user-button',
                }}
            >
                <MenuItem onClick={() => {dispatch(logout()); handleClose();}}>Uitloggen</MenuItem>
            </Menu>
        </div>
    );
}
