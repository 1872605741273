import React, {FC} from "react";
import {FormDialog} from "../../../../components/dialogs/FormDialog";
import {FormikHelpers, useFormik} from "formik";
import ApiErrorHelper from "../../../../helpers/ApiErrorHelper";
import {TextField} from "../../../../components/form/TextField";
import {useCreateUserMutation, useUpdateUserMutation} from "../../../../redux/api/user";
import {SelectField} from "../../../../components/form/SelectField";
import NotificationHelper from "../../../../helpers/NotificationHelper";

export type UserFormProps = {
    action: 'create' | 'edit',
    open: boolean,
    close: () => void,
    initialValues?: any,
}

export const Form: FC<UserFormProps> = ({action, initialValues, open, close}) => {
    const [createUser, {isLoading: isCreating}] = useCreateUserMutation();
    const [updateUser, {isLoading: isUpdating}] = useUpdateUserMutation();

    const submit = async (values: any, formikHelpers: FormikHelpers<any>) => {
        try {
            if (action === 'create') {
                await createUser(values).unwrap();
                NotificationHelper.showSuccess('De gebruiker is toegevoegd.');
            } else {
                await updateUser(values).unwrap();
                NotificationHelper.showSuccess('De gebruiker is gewijzigd.');
            }
            close();
        } catch (err) {
            ApiErrorHelper.processErrors(err, formikHelpers);
            formikHelpers.setSubmitting(false);
        }
    };

    const formik = useFormik({
        initialValues: initialValues ?? { role: 'ROLE_USER'},
        onSubmit: submit,
        enableReinitialize: true,
    });

    return (
        <FormDialog
            open={open}
            close={close}
            formik={formik}
            disableCheck
            isSubmitting={isCreating || isUpdating}
            handleSubmit={formik.handleSubmit}
            title={`Gebruiker ${action === 'create' ? 'toevoegen' : 'aanpassen'}`}
        >
            <TextField
                required
                fullWidth
                name="givenName"
                formik={formik}
                label={"Voornaam"} />
            <TextField
                required
                fullWidth
                name="familyName"
                formik={formik}
                label={"Achternaam"} />
            <TextField
                required
                fullWidth
                formik={formik}
                name="email"
                label={"E-mailadres"} />
            <SelectField
                formik={formik}
                label={"Rol"}
                name="role"
                options={[
                    {value: 'ROLE_USER', label: 'Gebruiker'},
                    {value: 'ROLE_ADMIN', label: 'Beheerder'}
                ]}
            />
        </FormDialog>
    );
}
