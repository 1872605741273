import React, {FC, PropsWithChildren} from 'react';
import {AppBar, Divider, Drawer, IconButton, styled, Toolbar, Typography, useTheme} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import logo from '../../assets/images/logo-v3.svg';
import {UserMenu} from "./menu/UserMenu";
import {MainMenu} from "./menu/MainMenu";
import {Breadcrumbs} from "./breadcrumbs/Breadcrumbs";
import {Snackbar} from "../ui/Snackbar";

type PrivateLayoutProperties = PropsWithChildren<{
    widgets?: Array<React.ReactElement>,
}>;

export const PrivateLayout: FC<PrivateLayoutProperties> = ({ children }) => {
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Root>
            <StyledAppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{marginRight: theme.spacing(2), ...(open && {display: 'none'})}}
                        size="large">
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        Ecommerce Management System
                    </Typography>
                    <StyledUserMenu />
                </Toolbar>
            </StyledAppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                }}
                variant="persistent"
                anchor="left"
                open={open}
                PaperProps={{style: {width: drawerWidth}}}
            >
                <DrawerHeader>
                    <img src={logo} alt="Ecommerce Management System" style={{width: 100}} />
                    <IconButton onClick={handleDrawerClose} size="large">
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <MainMenu />
            </Drawer>
            <Content open={open}>
                <DrawerHeader />
                <Breadcrumbs />

                {children}
            </Content>
            <Snackbar />
        </Root>
    );
}

const drawerWidth = 240;

const Root = styled('div')(({ theme }) => ({
    display: 'flex',
}));

const StyledAppBar = styled(AppBar)<{open: boolean}>(({ open, theme }) => ({
    width: (open ? `calc(100% - ${drawerWidth}px)` : undefined),
    marginLeft: (open ? drawerWidth : undefined),
    transition: theme.transitions.create(['margin', 'width'], {
        easing: (open ? theme.transitions.easing.easeOut : theme.transitions.easing.easeIn),
        duration: (open ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    ...theme.mixins.toolbar,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
    justifyContent: 'space-between',
}));

const Content = styled('main')<{open: boolean}>(({ open, theme }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: (open ? theme.transitions.easing.easeOut : theme.transitions.easing.easeIn),
        duration: (open ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen),
    }),
    marginLeft: (open ? 0 : -drawerWidth),
}));

const StyledUserMenu = styled(UserMenu)(({ theme }) => ({
    marginLeft: 'auto',
}));
