import React, {FC} from "react";
import {Button, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {PublicLayout} from "../../components/layout/PublicLayout";

export const Error404Page: FC<{}> = () => {
    const navigate = useNavigate();

    return (
        <PublicLayout>
            <Typography component="h1" variant="h5">
                Niet gevonden
            </Typography>
            <Typography>
                De pagina die je probeert te bezoeken bestaat niet. Klik op de knop om terug te gaan.
            </Typography>
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => navigate(-1)}
            >Ga terug</Button>
        </PublicLayout>
    );
}
