import React, {FC, useRef, useState} from "react";
import {Grid, Typography} from "@mui/material";
import {PrivateLayout} from "../../components/layout/PrivateLayout";
import DynamicDataGrid, {DynamicDataGridHandle} from "../../components/datagrid/DynamicDataGrid";
import {GridColDef} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {RowActions} from "../../components/datagrid/RowActions";
import {AddButton} from "../../components/form/AddButton";
import {ConfirmDialog} from "../../components/dialogs/ConfirmDialog";
import {useDeleteHandlingCostGroupMutation, useGetHandlingCostGroupsQuery} from "../../redux/api/handling-cost-groups";
import {HandlingCostGroupResponse} from "../../types/api/responses/handling-cost-groups/HandlingCostGroupResponse";
import {HandlingCostGroupForm} from "./HandlingCostGroups/HandlingCostGroupForm";
import {useGetSuppliersQuery} from "../../redux/api/suppliers";

export const HandlingCostGroupsPage: FC<{}> = () => {
    const [deleteHandlingCost] = useDeleteHandlingCostGroupMutation();
    const [formOpen, setFormOpen] = useState(false);
    const [selectedForEditing, setSelectedForEditing] = useState<HandlingCostGroupResponse|null>(null);
    const [selectedForDeleting, setSelectedForDeleting] = useState<HandlingCostGroupResponse|null>(null);
    const gridRef = useRef<DynamicDataGridHandle|null>(null);
    const {data: suppliers, isLoading: isSuppliersLoading} = useGetSuppliersQuery({});

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', flex: 1 },
        {
            field: 'name',
            headerName: 'Naam',
            minWidth: 400,
            flex: 1,
        },
        {
            field: 'supplier.name',
            headerName: 'Leverancier',
            minWidth: 400,
            flex: 1,
            renderCell: (params) => {
                if (suppliers) {
                    const supplier = suppliers['hydra:member']?.find(s => s['@id'] === params.row.supplier);

                    return supplier?.name;
                }

                return params.row.supplier;
            }
        },
        {
            field: 'actions',
            headerName: 'Acties',
            flex: 1,
            renderCell: (params) => {
                const actions = [
                    {
                        icon: <EditIcon/>,
                        label: 'Wijzigen',
                        onClick: () => {
                            setSelectedForEditing(params.row);
                            setFormOpen(true);
                        }
                    },
                    {
                        icon: <DeleteForeverIcon/>,
                        label: 'Verwijderen',
                        onClick: async () => {
                            setSelectedForDeleting(params.row);
                        }
                    },
                ];

                return <RowActions actions={actions}/>;
            },
        },
    ];

    const handleCreateClick = () => {
        setSelectedForEditing(null);
        setFormOpen(true);
    }

    const handleCloseFormDialog = () => {
        setFormOpen(false);
        gridRef.current?.reload();
    }

    const handleDeleteConfirm = async () => {
        if (selectedForDeleting) {
            await deleteHandlingCost(selectedForDeleting.id).unwrap();
            setSelectedForDeleting(null);
            gridRef.current?.reload();
        }
    }
    const handleDeleteDecline = () => {
        setSelectedForDeleting(null);
    }

    return (
        <PrivateLayout>
            <Grid container>
                <Grid item xs={12} sm={6} mb={3}>
                    <Typography component="h1" variant="h5">
                        Toeslag groepen
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} textAlign={'right'}>
                    <AddButton title="Toeslag groep toevoegen" onClick={handleCreateClick} />
                </Grid>

                <DynamicDataGrid
                    ref={gridRef}
                    columns={columns}
                    queryHook={useGetHandlingCostGroupsQuery}
                    autoHeight={true}
                    checkboxSelection={false}
                    disableRowSelectionOnClick={true}
                />
            </Grid>

            <HandlingCostGroupForm
                action={null === selectedForEditing ? 'create' : 'edit'}
                open={formOpen}
                close={handleCloseFormDialog}
                initialValues={selectedForEditing}
            />

            {selectedForDeleting && (
                <ConfirmDialog
                    title={'Toeslag groep verwijderen'}
                    message={`Weet je zeker dat je toeslag groep "${selectedForDeleting.name}" wilt verwijderen?`}
                    onYes={handleDeleteConfirm}
                    onNo={handleDeleteDecline}
                />
            )}
        </PrivateLayout>
    );
}
