import React, {FC, PropsWithChildren} from 'react';
import {Navigate} from "react-router-dom";
import {userHasRole} from "../../helpers/security";
import useJwt from "../../hooks/useJwt";

type SecureRouteProps = PropsWithChildren & {
    role?: string;
}

export const SecurePage: FC<SecureRouteProps> = ({role, children}) => {
    const jwt = useJwt();

    let redirect = true;
    if(jwt !== null) {
        if (jwt.exp && Date.now() < jwt.exp * 1000) redirect = false;

        if(role) redirect = !userHasRole(role, jwt.roles);
    } else {
        return <Navigate to="/login" />;
    }

    return redirect ? <Navigate to="/error/not-allowed" /> : children as JSX.Element;
}
