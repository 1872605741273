import React, {FC, useEffect, useState} from "react";
import {AppDialog} from "../../../components/dialogs/AppDialog";
import {useFormik} from "formik";
import {Box, Button, useTheme} from "@mui/material";
import ArrayHelper from "../../../helpers/ArrayHelper";
import {RepeatableField} from "../../../components/form/RepeatableField";
import {MoneyField} from "../../../components/form/MoneyField";
import {PercentageField} from "../../../components/form/PercentageField";
import {useGetMarginGroupsQuery} from "../../../redux/api/margin-groups";
import PriceCalculationService from "../../../services/PriceCalculationService";
import {
    useDeleteCalculationQuestionOptionPriceTierMutation
} from "../../../redux/api/calculation-question-option-price-tiers";
import {useGetOptionGroupQuery, useLazyGetOptionGroupQuery} from "../../../redux/api/option-groups";
import {useGetHandlingCostGroupQuery} from "../../../redux/api/handling-cost-groups";
import {CheckboxField} from "../../../components/form/CheckboxField";
import {useGetCalculationQuery} from "../../../redux/api/calculations";
import {useGetSupplierQuery} from "../../../redux/api/suppliers";

export type CalculationOptionPriceTierFormProps = {
    close: () => void,
    formik: ReturnType<typeof useFormik> | any, // Typed as OR any because of missing typing
    open: boolean,
    questionKey: string,
    optionKey: string,
    handlingGroup?: string,
};

export const CalculationOptionPriceTierForm: FC<CalculationOptionPriceTierFormProps> = ({
    close,
    open,
    formik,
    questionKey,
    optionKey,
    handlingGroup
}) => {
    const [deletePriceTier] = useDeleteCalculationQuestionOptionPriceTierMutation();
    const theme = useTheme();
    const {data: marginGroups} = useGetMarginGroupsQuery({});
    const {data: optionGroup} = useGetHandlingCostGroupQuery(handlingGroup || '');
    const questionData = ArrayHelper.get(formik.values, questionKey, {});
    const optionData = ArrayHelper.get(formik.values, optionKey, {});
    const minimumMarginAmount = ArrayHelper.get(questionData, 'minimumMargin.amount', -1);
    const {data: calculation} = useGetCalculationQuery(questionData.calculation);
    const {data: supplier} = useGetSupplierQuery(calculation?.supplier || '');

    const handlePriceTierRemove = (index: number) => {
        const priceTierToRemove = ArrayHelper.get(formik.values, `${optionKey}.priceTiers.${index}`);
        if (!priceTierToRemove) {
            return;
        }

        deletePriceTier(priceTierToRemove.id);
    }

    const calculateMargin = (row: number, field: string) => {
        const priceTiers = ArrayHelper.get(formik.values, `${optionKey}.priceTiers`, []);

        priceTiers.forEach((priceTier: any, index: number) => {
            if (index !== row) {
                return;
            }

            const priceTierKey = `${optionKey}.priceTiers.${index}`;
            const quantity = parseInt(ArrayHelper.get(formik.values, `${priceTierKey}.quantity`, 0));
            const setupCost = ArrayHelper.get(formik.values, `${priceTierKey}.setupCost.amount`, 0);
            const purchasePrice = ArrayHelper.get(formik.values, `${priceTierKey}.purchasePrice.amount`, 0);
            const purchasePricePerItem = ArrayHelper.get(formik.values, `${priceTierKey}.purchasePricePerItem.amount`, 0);
            const purchasePricePerItemExclBonus = ArrayHelper.get(formik.values, `${priceTierKey}.purchasePricePerItemExclBonus.amount`, 0);
            let marginPercentage = ArrayHelper.get(formik.values, `${priceTierKey}.marginPercentage`, 0);
            const price = ArrayHelper.get(formik.values, `${priceTierKey}.price.amount`, 0);
            let postageCost = 0;
            let bonusCost = 0;

            if (handlingGroup && optionGroup) {
                bonusCost = getBonus(row) || 0;
            }

            if (supplier && questionData.questionType === "amount") {
                postageCost = getPostageCost(row) || 0;
            }

            if (!isNaN(quantity) && quantity !== 0 && setupCost !== 0 && purchasePrice !== 0) {
                if (marginPercentage === -1) {
                    let marginGroup = ArrayHelper.get(formik.values, `${questionKey}.marginGroup`, '');
                    marginPercentage = ArrayHelper.get(formik.values, `${questionKey}.margin`, -1);

                    // Determine the margin to use
                    if (marginGroup) {
                        if (!marginGroups) {
                            return;
                        }

                        const selectedMarginGroup = marginGroups['hydra:member'].find(group => group['@id'] === marginGroup);
                        if (!selectedMarginGroup) {
                            return;
                        }

                        if (marginPercentage === -1) {
                            marginPercentage = selectedMarginGroup.margin;
                        }
                    }
                }

                if (setupCost == 0 && purchasePrice == 0 && marginPercentage == 0) {
                    formik.setFieldValue(`${priceTierKey}.purchasePricePerItem.amount`, 0);
                    formik.setFieldValue(`${priceTierKey}.purchasePricePerItemExclBonus.amount`, 0);
                    formik.setFieldValue(`${priceTierKey}.marginPercentage`, 0);
                    formik.setFieldValue(`${priceTierKey}.margin.amount`, 0);
                    formik.setFieldValue(`${priceTierKey}.price.amount`, 0);
                } else {
                    const result = PriceCalculationService.calculatePrices(field, quantity, setupCost, purchasePrice, purchasePricePerItem, marginPercentage, price, bonusCost, postageCost);
                    formik.setFieldValue(`${priceTierKey}.purchasePricePerItem.amount`, Math.round(result.purchasePricePerItem * 100) / 100);
                    formik.setFieldValue(`${priceTierKey}.purchasePricePerItemExclBonus.amount`, Math.round((purchasePrice + (setupCost / quantity) + (postageCost / quantity)) * 100) / 100);
                    formik.setFieldValue(`${priceTierKey}.marginPercentage`, 0 == result.marginPercentage ? '0' : Math.round(result.marginPercentage * 10) / 10);
                    formik.setFieldValue(`${priceTierKey}.margin.amount`, Math.round(result.totalMarginAmount * 100) / 100);
                    formik.setFieldValue(`${priceTierKey}.price.amount`, Math.round(result.price * 100) / 100);
                }
            }
        });
    }

    const getBonus = (row: number) => {
        if (optionGroup) {
            const quantity = ArrayHelper.get(formik.values, `${optionKey}.priceTiers.${row}.quantity`, 0);
            const tiers = optionGroup?.handlingCostGroupTiers ?? [];
            const sortedTiers = [...tiers].sort((a, b) => b.quantity - a.quantity);

            for (const tier of sortedTiers) {
                if (tier.quantity <= quantity) {
                    return tier?.handlingCostPerItem?.amount;
                }
            }
            return 0;
        }
    }

    const getPostageCost = (row: number) => {
        if (questionData.questionType === "amount" && supplier) {
            const quantity = ArrayHelper.get(formik.values, `${optionKey}.priceTiers.${row}.quantity`, 0);
            const price = optionData.priceTiers[row].purchasePrice.amount;

            if (supplier.noShippingCostsFrom.amount > (price * quantity)) {
                return supplier.shippingCosts.amount
            }

            return 0;
        }
    }

    return (
        <AppDialog
            open={open}
            onClose={close}
            title={`Staffelprijzen voor optie "${optionData.description || 'Nieuwe optie'}" ${questionData.question ? `van "${questionData.question}"` : ''}`}
            fullScreen={false}
            maxWidth={"lg"}
            fullWidth={true}
        >
            <div style={{marginBottom: theme.spacing(4)}}>
                Stel hieronder de staffelprijzen in voor de optie <strong>{optionData.description}</strong> van de
                vraag <strong>{questionData.question}</strong>.
            </div>

            <RepeatableField
                baseName={`${optionKey}.priceTiers`}
                formik={formik}
                onRemove={handlePriceTierRemove}
                columns={[
                    {
                        label: 'Vanaf aantal',
                        name: 'quantity',
                        field: 'number',
                        fieldProps: (row) => ({onBlur: () => calculateMargin(row, 'quantity')})
                    },
                    {
                        label: 'Instel kosten',
                        name: 'setupCost',
                        field: MoneyField,
                        fieldProps: (row) => ({onBlur: () => calculateMargin(row, 'setupCost')})
                    },
                    ...(supplier && questionData.questionType === "amount" ? [{
                        label: 'Verzendkosten',
                        name: 'postageCost',
                        field: MoneyField,
                        fieldProps: (row: number) => ({ value: getPostageCost(row), disabled: true })
                    }] : []),
                    {
                        label: 'Inkoopprijs',
                        name: 'purchasePrice',
                        field: MoneyField,
                        fieldProps: (row) => ({onBlur: () => calculateMargin(row, 'purchasePrice')})
                    },
                    {
                        label: 'Inkoopprijs p/s',
                        name: 'purchasePricePerItemExclBonus',
                        field: MoneyField,
                        fieldProps: (row) => ({onBlur: () => calculateMargin(row, 'purchasePricePerItem')})
                    },
                    ...(handlingGroup && optionGroup ? [{
                        label: 'Toeslag',
                        name: 'bonus',
                        field: MoneyField,
                        fieldProps: (row: number) => ({ value: getBonus(row), disabled: true })
                    }] : []),
                    ...(handlingGroup && optionGroup ? [{
                        label: 'Inkoopprijs p/s + toeslag',
                        name: 'purchasePricePerItem',
                        field: MoneyField,
                        fieldProps: (row: number) => ({onBlur: () => calculateMargin(row, 'purchasePricePerItem')})
                    }] : []),
                    {
                        label: 'Marge %',
                        name: 'marginPercentage',
                        field: PercentageField,
                        fieldProps: (row) => ({onBlur: () => calculateMargin(row, 'marginPercentage')})
                    },
                    {
                        label: 'Marge EUR',
                        name: 'margin',
                        field: MoneyField,
                        fieldProps: (row) => ({
                            disabled: true,
                            sx: {
                                '& .MuiInput-root': {
                                    padding: '0px 3px',
                                    backgroundColor: ArrayHelper.get(formik.values, `${optionKey}.priceTiers.${row}.margin.amount`, 0) < minimumMarginAmount
                                        ? '#ffc5b8'
                                        : '#efffe6'
                                }
                            }
                        })
                    },
                    {
                        label: 'Prijs',
                        name: 'price',
                        field: MoneyField,
                        fieldProps: (row) => ({
                            disabled: true,
                        })
                        // fieldProps: (row) => ({onBlur: () => calculateMargin(row, 'price')})
                    },
                ]}
            />

            <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 2}}>
                <Button color="primary" onClick={() => close()} variant={"contained"}>
                    Akkoord
                </Button>
            </Box>
        </AppDialog>
    );
}
