import React, {FC} from "react";
import {AppDialog} from "../../../components/dialogs/AppDialog";
import {useFormik} from "formik";
import {Box, Button, useTheme} from "@mui/material";
import ArrayHelper from "../../../helpers/ArrayHelper";

export type CalculationOptionBlockedFormProps = {
    close: () => void,
    formik: ReturnType<typeof useFormik> | any, // Typed as OR any because of missing typing
    open: boolean,
    questionKey: string,
    rowKey: any,
};

export const CalculationOptionBlockedForm: FC<CalculationOptionBlockedFormProps> = ({close, open, formik, questionKey, rowKey}) => {
    const theme = useTheme();
    const questionData = ArrayHelper.get(formik.values, questionKey, {});
    const optionData = ArrayHelper.get(formik.values, rowKey, {});

    const handleToggle = (optionId: number) => {
        const disabledForOptions = [...optionData.disabledForOptions];
        const index = disabledForOptions.indexOf(optionId);

        if (index === -1) {
            disabledForOptions.push(optionId);
        } else {
            disabledForOptions.splice(index, 1);
        }

        formik.setFieldValue(`${rowKey}.disabledForOptions`, disabledForOptions);
    }

    const isChecked = (optionId: number) => {
        return optionData.disabledForOptions
            && optionData.disabledForOptions?.indexOf(optionId) !== -1;
    }

    return (
        <AppDialog
            open={open}
            onClose={close}
            title={`Optie "${optionData.description}" van "${questionData.question}" blokkeren`}
            fullScreen={false}
        >
            <div style={{marginBottom: theme.spacing(4)}}>
                Deze optie blokkeren indien 1 van de volgende opties door de klant is geselecteerd:
            </div>

            {formik.values.filter((value:any, index: any) => index < questionKey).map((question: any, index: number) => (
                <div key={`blocked-option-question-${question.id}`} style={{marginBottom: theme.spacing(3)}}>
                    <strong>{index+1}: {question.question}</strong>
                    <div>
                        {question.options.map((option: any) => (
                            <div key={`blocked-option-${option.id}`}>
                                <label>
                                    <input type={'checkbox'} onClick={() => handleToggle(option.id)} checked={isChecked(option.id)} />
                                    {option.description}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
            ))}

            <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 2}}>
                <Button color="primary" onClick={() => close()} variant={"contained"}>
                    Sluiten
                </Button>
            </Box>
        </AppDialog>
    );
}
