import api from "./api";
import {DivisionsResponse} from "../../types/api/responses/exact-online/DivisionsResponse";
import {AuthUrlResponse} from "../../types/api/responses/exact-online/AuthUrlResponse";
import {AuthStatusResponse} from "../../types/api/responses/exact-online/AuthStatusResponse";

const exactOnlineApi = api.injectEndpoints({
    endpoints: build => ({
        getAuthStatus: build.query<AuthStatusResponse, void>({
            query: () => ({
                url: 'api/exact/auth-status',
            })
        }),

        getAuthUrl: build.query<AuthUrlResponse, void>({
            query: () => ({
                url: 'api/exact/auth-url',
            })
        }),

        getDivisions: build.query<DivisionsResponse, void>({
            query: () => ({
                url: 'api/exact/divisions',
            })
        }),
    }),
});

export const {
    useGetAuthStatusQuery,
    useGetAuthUrlQuery,
    useLazyGetDivisionsQuery,
} = exactOnlineApi;

export default exactOnlineApi;
