import React, {FC} from "react";
import {RequestFilter} from "../../../types/api/requests/DynamicListRequest";
import {Filter} from "../../../components/datagrid/Filter";
import {Autocomplete, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField} from "@mui/material";
import {useGetSuppliersQuery} from "../../../redux/api/suppliers";
import {useGetMagento2TaxClassesQuery} from "../../../redux/api/extra";

export type CalculationsFilterProps = {
    filters: RequestFilter,
    onFiltersChanged: (filters: RequestFilter) => void;
};

let timeout: ReturnType<typeof setTimeout>;

export const CalculationsFilter: FC<CalculationsFilterProps> = ({filters, onFiltersChanged}) => {
    const {data: suppliers} = useGetSuppliersQuery({page: 1, pageSize: 1000});
    const {data: taxClasses} = useGetMagento2TaxClassesQuery();

    const addFilter = (field: string, value: string) => {
        if(timeout) {
            clearTimeout(timeout);
        }

        timeout = setTimeout(() => {
            const newFilters = [];
            filters.forEach(filter => {
                if (filter.field !== field) {
                    newFilters.push(filter);
                }
            });

            newFilters.push({field, value});
            onFiltersChanged(newFilters);
        }, 250);
    };


    return (
        <Filter>
            <FormControl variant="outlined" size="small">
                <InputLabel id="filter-active">Actief</InputLabel>
                <Select
                    labelId="filter-active-label"
                    id="filter-active"
                    defaultValue=""
                    onChange={(e: SelectChangeEvent<string>) => addFilter('active', e.target.value)}
                    label="Actief"
                >
                    <MenuItem value=""><em>Alles</em></MenuItem>
                    <MenuItem value="1">Ja</MenuItem>
                    <MenuItem value="0">Nee</MenuItem>
                </Select>
            </FormControl>
            <TextField
                label="Naam"
                variant="outlined"
                name="filter-name"
                onChange={(e) => addFilter('name', e.target.value)}
                size="small"
            />
            <TextField
                label="SKU"
                variant="outlined"
                name="filter-sku"
                onChange={(e) => addFilter('sku', e.target.value)}
                size="small"
            />
            <TextField
                label="Tag"
                variant="outlined"
                name="filter-tag"
                onChange={(e) => addFilter('tags.tag', e.target.value)}
                size="small"
            />
            {suppliers && (
                <Autocomplete
                    options={suppliers['hydra:member'].map(supplier => ({value: supplier.id, label: supplier.name}))}
                    onChange={(event, newValue) => addFilter('supplier', newValue?.value as unknown as string || '')}
                    renderInput={(params) => <TextField {...params} label="Leverancier" size="small" />}
                />
            )}
            {taxClasses && (
                <Autocomplete
                    options={taxClasses['taxClasses'].filter(taxClass => taxClass.class_type === 'PRODUCT').map(taxClass => ({value: taxClass.class_id, label: taxClass.class_name}))}
                    onChange={(event, newValue) => addFilter('taxGroup', newValue?.value as unknown as string || '')}
                    renderInput={(params) => <TextField {...params} label="BTW groep" size="small" />}
                />
            )}
            <FormControl variant="outlined" size="small">
                <InputLabel id="filter-stockProduct">Voorraad product</InputLabel>
                <Select
                    labelId="filter-stockProduct-label"
                    id="filter-stockProduct"
                    defaultValue=""
                    onChange={(e: SelectChangeEvent<string>) => addFilter('stockProduct', e.target.value)}
                    label="Voorraad product"
                >
                    <MenuItem value=""><em>Alles</em></MenuItem>
                    <MenuItem value="1">Ja</MenuItem>
                    <MenuItem value="0">Nee</MenuItem>
                </Select>
            </FormControl>
            <FormControl variant="outlined" size="small">
                <InputLabel id="filter-inStock">Op voorraad</InputLabel>
                <Select
                    labelId="filter-inStock-label"
                    id="filter-inStock"
                    defaultValue=""
                    onChange={(e: SelectChangeEvent<string>) => addFilter('inStock', e.target.value)}
                    label="Op voorraad"
                >
                    <MenuItem value=""><em>Alles</em></MenuItem>
                    <MenuItem value="1">Ja</MenuItem>
                    <MenuItem value="0">Nee</MenuItem>
                </Select>
            </FormControl>
            <FormControl variant="outlined" size="small">
                <InputLabel id="filter-concept">Concept</InputLabel>
                <Select
                    labelId="filter-concept-label"
                    id="filter-concept"
                    defaultValue=""
                    onChange={(e: SelectChangeEvent<string>) => addFilter('concept', e.target.value)}
                    label="Concept"
                >
                    <MenuItem value=""><em>Alles</em></MenuItem>
                    <MenuItem value="1">Ja</MenuItem>
                    <MenuItem value="0">Nee</MenuItem>
                </Select>
            </FormControl>
        </Filter>
    );
}
