import React, {FC, useEffect} from 'react';
import {useFormik} from "formik";
import {useGetAuthStatusQuery, useGetAuthUrlQuery, useLazyGetDivisionsQuery} from "../../../../redux/api/exact-online";
import {LoadableContainer} from "../../../../components/ui/LoadableContainer";
import {SettingsCard} from "./SettingsCard";
import {SelectField} from "../../../../components/form/SelectField";
import {Alert, Button} from "@mui/material";

export type ExactOnlineFormProps = {
    formik: ReturnType<typeof useFormik>
}

export const ExactOnlineForm: FC<ExactOnlineFormProps> = ({formik}) => {
    const {data: authStatus, isLoading: isAuthStatusLoading} = useGetAuthStatusQuery();
    const {data: authUrl, isLoading: isAuthUrlLoading} = useGetAuthUrlQuery();
    const [getDivisions, {data: divisions, isLoading: isDivisionsLoading}] = useLazyGetDivisionsQuery();

    useEffect(() => {
        if (authStatus && authStatus.status === 'ok') {
            getDivisions();
        }
    }, [authStatus]);

    return (
        <LoadableContainer isLoading={isAuthStatusLoading || isAuthUrlLoading || isDivisionsLoading}>
            <>
                {authStatus && authStatus.status !== 'ok' && authUrl && (
                    <SettingsCard title={'Exact Online Toestemming'}>
                        {authStatus.status === 'error' && (
                            <Alert severity="error">
                                Er is een fout opgetreden bij het verbinden met Exact Online:<br />
                                {authStatus.error}<br /><br />
                                Klik op de knop om opnieuw toestemming te geven.
                            </Alert>
                        )}
                        <p>Om de Exact Online koppeling te kunnen gebruiken moet u eerst toestemming geven.</p>
                        <Button href={authUrl!.url} rel="noreferrer" variant={'contained'}>Geef toestemming</Button>
                    </SettingsCard>
                )}

                {authStatus && authStatus.status === 'ok' && divisions && (
                    <SettingsCard title={'Exact Online Instellingen'}>
                        <SelectField
                            formik={formik}
                            options={Object.entries(divisions.divisions).map(([key, value]) => ({value: key, label: value as string}))}
                            name={'exact/division'}
                            label={'Administratie'}
                        />
                    </SettingsCard>
                )}
            </>
        </LoadableContainer>
    )
}
