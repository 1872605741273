import React, {FC} from "react";
import {
    Paper,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableHead,
    TableRow,
    TextFieldProps as MuiTextFieldProps
} from "@mui/material";
import {useFormik} from "formik";
import {useGetOptionGroupCalculationsQuery} from "../../../redux/api/option-groups";
import {LoadableContainer} from "../../../components/ui/LoadableContainer";


export type OptionGroupLinkedCalculationProps = {
    formik: ReturnType<typeof useFormik> | any, // Typed as OR any because of missing typing
};

export const OptionGroupLinkedCalculation: FC<OptionGroupLinkedCalculationProps> = ({formik}) => {
    const {data: calculations, isLoading: isLoadingCalculations} = useGetOptionGroupCalculationsQuery(formik.values.id);

    return (
        <LoadableContainer isLoading={isLoadingCalculations}>
            <TableContainer component={Paper}>
                <Table sx={{background: ""}}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Product ID</TableCell>
                            <TableCell>SKU</TableCell>
                            <TableCell>Naam</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {calculations?.['hydra:member']?.map((calculation: any) => (
                            <TableRow key={calculation.id}>
                                <TableCell>{calculation.id}</TableCell>
                                <TableCell>{calculation.sku}</TableCell>
                                <TableCell>{calculation.name}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </LoadableContainer>
    );
}
