import React, {FC} from 'react';
import {TextField} from "../../../../components/form/TextField";
import {useFormik} from "formik";
import {SettingsCard} from "./SettingsCard";
import {RepeatableField} from "../../../../components/form/RepeatableField";
import {SelectField} from "../../../../components/form/SelectField";
import {useGetMagento2AttributesQuery} from "../../../../redux/api/magento2";

export type Magento2FormProps = {
    formik: ReturnType<typeof useFormik>
}

export const Magento2Form: FC<Magento2FormProps> = ({formik}) => {
    const {data: magento2Attributes} = useGetMagento2AttributesQuery();

    return (
        <>
            <SettingsCard title="Webshop Instellingen">
                <TextField
                    required
                    fullWidth
                    name="webshop/baseUrl"
                    formik={formik}
                    label={"Webshop URL"} />
                <TextField
                    required
                    fullWidth
                    name="webshop/api/username"
                    formik={formik}
                    label={"Webshop API Gebruikersnaam"} />
                <TextField
                    required
                    fullWidth
                    type="password"
                    name="webshop/api/password"
                    formik={formik}
                    label={"Webshop API Wachtwoord"} />
            </SettingsCard>
            <SettingsCard title="Product Attribuut Instellingen">
                <TextField
                    required
                    fullWidth
                    name="webshop/attribute/min_order_amount"
                    formik={formik}
                    label={"Minimale afname"} />
                <SelectField
                    formik={formik}
                    label={'Levertijd in dagen'}
                    name={`webshop/attribute/delivery_time_days`}
                    options={magento2Attributes
                        && magento2Attributes['hydra:member'].map(attribute => ({label: attribute.frontend_label, value: attribute.attribute_code}))}
                />
                <SelectField
                    formik={formik}
                    label={'Kleur'}
                    name={`webshop/attribute/color`}
                    options={magento2Attributes
                        && magento2Attributes['hydra:member'].map(attribute => ({label: attribute.frontend_label, value: attribute.attribute_code}))}
                />
                <SelectField
                    formik={formik}
                    label={'Drukkleuren'}
                    name={`webshop/attribute/printing_colors`}
                    options={magento2Attributes
                        && magento2Attributes['hydra:member'].map(attribute => ({label: attribute.frontend_label, value: attribute.attribute_code}))}
                />
                <TextField
                    required
                    fullWidth
                    name="webshop/attribute/calculation_id"
                    formik={formik}
                    label={"Calculatie ID"} />
            </SettingsCard>

            <SettingsCard title={"Extra product kenmerken"}>
                <RepeatableField
                    formik={formik}
                    columns={[
                        {name: 'name', label: 'Naam', field: 'text'},
                        {
                            name: 'attribute_code',
                            label: 'Magento 2 Attribuut Code',
                            field: SelectField,
                            fieldProps: () => {
                                return {
                                    options: magento2Attributes
                                        && magento2Attributes['hydra:member'].map(attribute => ({
                                            label: attribute.frontend_label,
                                            value: attribute.attribute_code
                                        }))
                                }
                            }
                        },
                    ]}
                    baseName={'webshop/attribute/custom_product_attributes'}
                />
            </SettingsCard>
        </>
    )
}
