import React, {FC} from "react";
import {RequestFilter} from "../../../types/api/requests/DynamicListRequest";
import {Filter} from "../../../components/datagrid/Filter";
import {Autocomplete, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField} from "@mui/material";
import {useGetMarginGroupsQuery} from "../../../redux/api/margin-groups";

export type CalculationQuestionsFilterProps = {
    filters: RequestFilter,
    onFiltersChanged: (filters: RequestFilter) => void;
};

let timeout: ReturnType<typeof setTimeout>;

export const CalculationQuestionsFilter: FC<CalculationQuestionsFilterProps> = ({filters, onFiltersChanged}) => {
    const {data: marginGroups} = useGetMarginGroupsQuery({page: 1, pageSize: 1000});

    const addFilter = (field: string, value: string) => {
        if(timeout) {
            clearTimeout(timeout);
        }

        timeout = setTimeout(() => {
            const newFilters = [];
            filters.forEach(filter => {
                if (filter.field !== field) {
                    newFilters.push(filter);
                }
            });

            newFilters.push({field, value});
            onFiltersChanged(newFilters);
        }, 250);
    };


    return (
        <Filter>
            <FormControl variant="outlined" size="small">
                <InputLabel id="filter-active">Actief</InputLabel>
                <Select
                    labelId="filter-active-label"
                    id="filter-active"
                    defaultValue=""
                    onChange={(e: SelectChangeEvent<string>) => addFilter('active', e.target.value)}
                    label="Actief"
                >
                    <MenuItem value=""><em>Alles</em></MenuItem>
                    <MenuItem value="1">Ja</MenuItem>
                    <MenuItem value="0">Nee</MenuItem>
                </Select>
            </FormControl>
            <FormControl variant="outlined" size="small">
                <InputLabel id="filter-shared">Gedeeld</InputLabel>
                <Select
                    labelId="filter-shared-label"
                    id="filter-shared"
                    defaultValue=""
                    onChange={(e: SelectChangeEvent<string>) => addFilter('shared', e.target.value)}
                    label="Actief"
                >
                    <MenuItem value=""><em>Alles</em></MenuItem>
                    <MenuItem value="1">Ja</MenuItem>
                    <MenuItem value="0">Nee</MenuItem>
                </Select>
            </FormControl>
            <TextField
                label="Vraag"
                variant="outlined"
                name="filter-question"
                onChange={(e) => addFilter('question', e.target.value)}
                size="small"
            />
            <TextField
                label="Calculatie SKU"
                variant="outlined"
                name="filter-calculation-sku"
                onChange={(e) => addFilter('calculation.sku', e.target.value)}
                size="small"
            />
            <TextField
                label="Hoofd SKU"
                variant="outlined"
                name="filter-main-sku"
                onChange={(e) => addFilter('mainSku', e.target.value)}
                size="small"
            />
            <TextField
                label="Optie SKU"
                variant="outlined"
                name="filter-option-sku"
                onChange={(e) => addFilter('options.sku', e.target.value)}
                size="small"
            />
            {marginGroups && (
                <Autocomplete
                    options={marginGroups['hydra:member'].map(marginGroup => ({value: marginGroup.id, label: marginGroup.name}))}
                    onChange={(event, newValue) => addFilter('marginGroup', newValue?.value as unknown as string || '')}
                    renderInput={(params) => <TextField {...params} label="Marge groep" size="small" />}
                />
            )}
        </Filter>
    );
}
