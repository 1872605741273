export type PriceCalculationServiceResult = {
    purchasePricePerItem: number;
    marginPercentage: number;
    totalMarginAmount: number;
    price: number;
}

class PriceCalculationService
{
    /**
     * @param changedField
     * @param quantity
     * @param setupCost
     * @param purchasePrice
     * @param purchasePricePerItem
     * @param marginPercentage
     * @param price
     * @param bonusCost
     * @param postageCost
     */
    public calculatePrices(
        changedField: string,
        quantity: number,
        setupCost: number,
        purchasePrice: number,
        purchasePricePerItem: number,
        marginPercentage: number,
        price: number,
        bonusCost: number,
        postageCost: number
    ): PriceCalculationServiceResult {
        switch(changedField) {
            case 'quantity':
            case 'setupCost':
            case 'purchasePrice':
            case 'bonusCost':
            case 'postageCost':
                return this.calculateDefault(
                    quantity,
                    this.calculatePurchasePricePerItem(quantity, setupCost, purchasePrice, bonusCost, postageCost),
                    marginPercentage,
                );

            case 'purchasePricePerItem':
                return this.calculateDefault(
                    quantity,
                    purchasePricePerItem,
                    marginPercentage,
                );

            case 'marginPercentage':
                return this.calculateBasedOnMarginPercentage(
                    quantity,
                    purchasePricePerItem,
                    marginPercentage,
                );

            case 'price':
                return this.calculateBasedOnPrice(
                    quantity,
                    purchasePricePerItem,
                    price,
                );
        }

        return {
            purchasePricePerItem: 0,
            marginPercentage: 0,
            totalMarginAmount: 0,
            price: 0,
        };
    }

    /**
     * @param quantity
     * @param setupCost
     * @param purchasePrice
     * @param bonusCost
     * @param postageCost
     * @private
     */
    private calculatePurchasePricePerItem(quantity: number, setupCost: number, purchasePrice: number, bonusCost: number, postageCost: number): number
    {
        return (setupCost / quantity) + purchasePrice + bonusCost + (postageCost / quantity);
    }

    /**
     * @param quantity
     * @param purchasePricePerItem
     * @param marginPercentage
     * @private
     */
    private calculateDefault(
        quantity: number,
        purchasePricePerItem: number,
        marginPercentage: number,
    ): PriceCalculationServiceResult {
        const totalCost = purchasePricePerItem;

        // Calculate the margin to add
        const marginMoney = (totalCost / (1 - (marginPercentage / 100))) - totalCost;

        // Calculate the price including the margin
        const price = marginMoney + totalCost;

        return {
            purchasePricePerItem,
            marginPercentage,
            totalMarginAmount: marginMoney * quantity,
            price,
        };
    }

    /**
     * @param quantity
     * @param purchasePricePerItem
     * @param marginPercentage
     * @private
     */
    private calculateBasedOnMarginPercentage(
        quantity: number,
        purchasePricePerItem: number,
        marginPercentage: number,
    ): PriceCalculationServiceResult {
        const totalCost = purchasePricePerItem;

        return {
            purchasePricePerItem,
            marginPercentage,
            totalMarginAmount: ((totalCost / (1 - (marginPercentage / 100))) - totalCost) * quantity,
            price: (totalCost  / (1 - (marginPercentage / 100))),
        }
    }

    /**
     * @param quantity
     * @param purchasePricePerItem
     * @param price
     * @private
     */
    private calculateBasedOnPrice(
        quantity: number,
        purchasePricePerItem: number,
        price: number,
    ): PriceCalculationServiceResult {
        const totalCost = purchasePricePerItem;

        return {
            purchasePricePerItem,
            price,
            marginPercentage: ((price - totalCost) / totalCost) * 100,
            totalMarginAmount: (price - totalCost) * quantity,
        }
    }
}

export default new PriceCalculationService() as PriceCalculationService;
