import api from "./api";
import DynamicListRequest from "../../types/api/requests/DynamicListRequest";
import UrlHelper from "../../helpers/UrlHelper";
import {ShippingTimeGroupsResponse} from "../../types/api/responses/shipping-time-groups/ShippingTimeGroupsResponse";
import {ShippingTimeGroupResponse} from "../../types/api/responses/shipping-time-groups/ShippingTimeGroupResponse";
import {ShippingTimeGroupRequest} from "../../types/api/requests/shipping-time-group/ShippingTimeGroupRequest";

const shippingTimeGroupApi = api.injectEndpoints({
    endpoints: build => ({
        getShippingTimeGroups: build.query<ShippingTimeGroupsResponse, DynamicListRequest>({
            query: (request) => UrlHelper.buildUrl('api/shipping_time_groups', request),
            providesTags: ['shipping-time-groups'],
        }),

        createShippingTimeGroup: build.mutation<ShippingTimeGroupResponse, ShippingTimeGroupRequest>({
            query: (body) => ({
                url: '/api/shipping_time_groups',
                method: 'POST',
                body: body,
            }),
            invalidatesTags: ['shipping-time-groups']
        }),

        updateShippingTimeGroup: build.mutation<ShippingTimeGroupResponse, ShippingTimeGroupRequest>({
            query: (body) => ({
                url: `/api/shipping_time_groups/${body.id}`,
                method: 'PUT',
                body: body,
            }),
            invalidatesTags: ['shipping-time-groups']
        }),

        deleteShippingTimeGroup: build.mutation<boolean, number>({
            query: (id) => ({
                url: `/api/shipping_time_groups/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['shipping-time-groups']
        }),
    }),
});

export const {
    useGetShippingTimeGroupsQuery,
    useCreateShippingTimeGroupMutation,
    useUpdateShippingTimeGroupMutation,
    useDeleteShippingTimeGroupMutation,
} = shippingTimeGroupApi;

export default shippingTimeGroupApi;
