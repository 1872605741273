import React, {ChangeEvent, FC} from "react";
import {ButtonProps} from "@mui/material/Button/Button";
import {useUploadImageMutation} from "../../redux/api/images";
import {UploadFileOutlined} from "@mui/icons-material";
import {Button, LinearProgress} from "@mui/material";
import {ImageResponse} from "../../types/api/responses/image/ImageResponse";

type ImageUploadFieldProps = ButtonProps & {
    label?: string,
    onUploaded?: (image: ImageResponse, file: File) => void,
}

export const ImageUploadField: FC<ImageUploadFieldProps> = ({label, onUploaded}) => {
    const [uploadImage, {isLoading: isUploading}] = useUploadImageMutation();

    const handleFilesChange = async (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || 0 === e.target.files.length) {
            return;
        }

        let formData = new FormData();
        formData.append('file', e.target.files[0]);

        const imageData = await uploadImage(formData).unwrap();
        onUploaded && onUploaded(imageData, e.target.files[0]);
    }

    return (
        <Button
            component="label"
            variant="outlined"
            startIcon={isUploading ? <LinearProgress
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                }}
            /> : <UploadFileOutlined />}
            sx={{ marginRight: "1rem" }}
        >
            {label ? label : 'Afbeelding uploaden'}
            <input type="file" accept=".png,.jpg,.jpeg,image/png,image/jpg,image/jpeg" hidden onChange={handleFilesChange} />
        </Button>
    );
}
