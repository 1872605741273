import api from "./api";

const actionTokenApi = api.injectEndpoints({
    endpoints: build => ({
        validateActionToken: build.mutation<{email: string}, {token: string}>({
            query: (data) => ({
                url: `/api/public/action-tokens/validate`,
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    "content-type": "application/ld+json"
                }
            })
        }),
    }),
});

export const {
    useValidateActionTokenMutation,
} = actionTokenApi;

export default actionTokenApi;
