import api from "./api";
import DynamicListRequest from "../../types/api/requests/DynamicListRequest";
import UrlHelper from "../../helpers/UrlHelper";
import {OptionGroupsResponse} from "../../types/api/responses/option-group/OptionGroupsResponse";
import {OptionGroupResponse} from "../../types/api/responses/option-group/OptionGroupResponse";
import {OptionGroupRequest} from "../../types/api/requests/option-group/OptionGroupRequest";

const optionGroupApi = api.injectEndpoints({
    endpoints: build => ({
        getOptionGroups: build.query<OptionGroupsResponse, DynamicListRequest>({
            query: (request) => UrlHelper.buildUrl('api/option_groups', request),
            providesTags: ['option-groups'],
        }),

        getOptionGroup: build.query<OptionGroupResponse, string>({
            query: (url) => ({
                url: url,
                method: 'GET',
            }),
            providesTags: ['option-groups']
        }),

        getOptionDuplicationName: build.query<string, string>({
            query: (name) => ({
                url: '/option_groups/next_duplicate_name',
                params: { name },
                method: 'GET',
            }),
        }),

        getOptionDuplicationSku: build.query<string, string>({
            query: (sku) => ({
                url: '/option_groups/next_duplicate_sku',
                params: { sku },
                method: 'GET',
            }),
        }),

        createOptionGroup: build.mutation<OptionGroupResponse, OptionGroupRequest>({
            query: (body) => ({
                url: '/api/option_groups',
                method: 'POST',
                body: body,
            }),
            invalidatesTags: ['option-groups']
        }),

        updateOptionGroup: build.mutation<OptionGroupResponse, OptionGroupRequest>({
            query: (body) => ({
                url: `/api/option_groups/${body.id}`,
                method: 'PUT',
                body: body,
            }),
            invalidatesTags: ['option-groups', 'calculation-questions', 'calculations']
        }),

        deleteOptionGroup: build.mutation<boolean, number>({
            query: (id) => ({
                url: `/api/option_groups/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['option-groups', 'calculation-questions', 'calculations']
        }),

        deleteHandlingCostGroup: build.mutation<boolean, number>({
            query: (id) => ({
                url: `/api/option_groups/${id}/remove_handling_cost_group`,
                method: 'PUT',
                headers: {
                    "content-type": "application/ld+json"
                },
            })
        }),
        deleteSupplierFromOptionGroup: build.mutation<boolean, number>({
            query: (id) => ({
                url: `/api/option_groups/${id}/remove_supplier`,
                method: 'PUT',
                headers: {
                    "content-type": "application/ld+json"
                },
            })
        }),
        deleteShippingTimeGroup: build.mutation<boolean, number>({
            query: (id) => ({
                url: `/api/option_groups/${id}/remove_shipping_time_group`,
                method: 'PUT',
                headers: {
                    "content-type": "application/ld+json"
                },
            })
        }),

        bulkActivateOptionGroup: build.mutation<void, Array<number>>({
            query: (request) => ({
                url: 'api/option_groups/activate',
                method: 'PUT',
                body: {optionGroupIds: request},
            }),
            invalidatesTags: ['option-groups']
        }),

        bulkDeactivateOptionGroup: build.mutation<void, Array<number>>({
            query: (request) => ({
                url: 'api/option_groups/deactivate',
                method: 'PUT',
                body: {optionGroupIds: request},
            }),
            invalidatesTags: ['option-groups']
        }),

        getOptionGroupCalculations: build.query<any, number>({
            query: (id) => ({
                url: `/api/option_groups/${id}/calculations`,
                method: 'GET',
            }),
        }),
    }),
});

export const {
    useGetOptionGroupsQuery,
    useGetOptionGroupQuery,
    useCreateOptionGroupMutation,
    useUpdateOptionGroupMutation,
    useDeleteOptionGroupMutation,
    useLazyGetOptionDuplicationNameQuery,
    useLazyGetOptionDuplicationSkuQuery,
    useLazyGetOptionGroupQuery,
    useDeleteHandlingCostGroupMutation,
    useDeleteSupplierFromOptionGroupMutation,
    useDeleteShippingTimeGroupMutation,
    useBulkActivateOptionGroupMutation,
    useBulkDeactivateOptionGroupMutation,
    useGetOptionGroupCalculationsQuery,
} = optionGroupApi;

export default optionGroupApi;
