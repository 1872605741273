import React, {FC} from "react";
import {Button, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {PublicLayout} from "../../components/layout/PublicLayout";

export const Error401Page: FC<{}> = () => {
    const navigate = useNavigate();

    return (
        <PublicLayout>
            <Typography component="h1" variant="h5">
                Geen toegang
            </Typography>
            <Typography>
                Helaas heb je geen toegang tot dit onderdeel. Klik op de knop om terug te gaan.
            </Typography>
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => navigate(-1)}
            >Ga Terug</Button>
        </PublicLayout>
    );
}
