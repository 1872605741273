import * as React from "react";
import {FC, forwardRef, PropsWithChildren} from "react";
import {AppBar, Box, Dialog, DialogProps, IconButton, Slide, Toolbar, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {TransitionProps} from "@mui/material/transitions";

export type AppDialogProps = DialogProps & PropsWithChildren & {
    title: string;
    onClose: () => void;
}

export const AppDialog: FC<AppDialogProps> = forwardRef(({open, title, onClose, children, ...restProps}, ref) => {
    return (
        <Dialog ref={ref} open={open} onClose={onClose} TransitionComponent={Transition} {...restProps}>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {title}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <Box sx={{padding: 2}}>
                { children }
            </Box>
        </Dialog>
    );
});

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});
