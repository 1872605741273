import {store} from "../redux/store";
import {showSnackbar} from "../redux/slices/layout";

/**
 * Helper class for Notifications
 */
class NotificationHelper
{
    public showSuccess(message: string): void
    {
        store.dispatch(showSnackbar({type: 'success', message: message}));
    }

    public showError(message: string): void
    {
        store.dispatch(showSnackbar({type: 'error', message: message}));
    }
}

export default new NotificationHelper() as NotificationHelper;
