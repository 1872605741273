import api from "./api";

const calculationAttributesApi = api.injectEndpoints({
    endpoints: build => ({
        deleteCalculationAttribute: build.mutation<boolean, number>({
            query: (id) => ({
                url: `/api/calculation_attributes/${id}`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const {
    useDeleteCalculationAttributeMutation,
} = calculationAttributesApi;

export default calculationAttributesApi;
