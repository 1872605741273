import React, {FC} from "react";
import {ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {userHasRole} from "../../../helpers/security";
import useJwt from "../../../hooks/useJwt";


type MainMenuItemProps = {
    to: string,
    icon: React.ReactElement,
    title: string,
    role?: string
};

export const MainMenuItem: FC<MainMenuItemProps> = ({to, icon, title, role}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const jwt = useJwt();

    if (role) {
        if (!jwt) return <></>;
        if (!userHasRole(role, jwt.roles)) return <></>;
    }

    return (
        <ListItemButton onClick={() => navigate(to)} selected={0 === location.pathname.indexOf(to)}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText>{title}</ListItemText>
        </ListItemButton>
    );
}
