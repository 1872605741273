import React, {FC, FormEvent} from "react";
import {AppDialog} from "../../../components/dialogs/AppDialog";
import {Box, Button, Typography} from "@mui/material";
import {LoadingButton} from "../../../components/form/LoadingButton";
import {useImportCalculcationOptionsMutation} from "../../../redux/api/calculations";
import NotificationHelper from "../../../helpers/NotificationHelper";

export type ImportCalculcationOptionsDialogProps = {
    close: () => void;
}

export const ImportCalculcationOptionsDialog: FC<ImportCalculcationOptionsDialogProps> = ({close}) => {
    const [importCalculationOptions, {isLoading: isSubmitting}] = useImportCalculcationOptionsMutation();

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        const formData = new FormData(e.target as HTMLFormElement);
        try {
            await importCalculationOptions(formData);
            NotificationHelper.showSuccess('De opties zijn succesvol bijgewerkt.');
            close();
        } catch(e) {
            NotificationHelper.showError('Er is een fout opgetreden bij het bijwerken van de opties. Probeer het later nog eens.');
        }
    };

    return (
        <AppDialog open={true} onClose={close} title={"Calculcatie opties bijwerken"} fullScreen={false}>
            <form onSubmit={handleSubmit} encType={'multipart/form-data'}>
                <Typography>Met deze functie is het mogelijk om de door dit systeem geëxporteerde bestanden met wijzigingen weer te importeren, om op die manier snel aanpassingen te kunnen doen aan product opties.</Typography>
                <Typography sx={{marginTop: 3}}>Selecteer het te importeren Excel-bestand:</Typography>
                <input type="file" name="file" />
                <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 2}}>
                    <Button color="primary" onClick={() => close()} variant="outlined" sx={{marginRight: 1}}>
                        Annuleren
                    </Button>
                    <LoadingButton loading={isSubmitting} autoFocus color="primary" type="submit" variant="contained">
                        Importeren
                    </LoadingButton>
                </Box>
            </form>
        </AppDialog>
    );
};
