import React, {FC} from 'react';
import {TextField} from "../../../../components/form/TextField";
import {useFormik} from "formik";
import {SettingsCard} from "./SettingsCard";
import {Button, InputAdornment} from "@mui/material";

export type ApiFormProps = {
    formik: ReturnType<typeof useFormik>
}

export const ApiForm: FC<ApiFormProps> = ({formik}) => {
    const randomString = (len: number) => {
        const charSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let randomString = '';
        for (let i = 0; i < len; i++) {
            let randomPoz = Math.floor(Math.random() * charSet.length);
            randomString += charSet.substring(randomPoz,randomPoz+1);
        }

        return randomString;
    }

    return (
        <>
            <SettingsCard title="API Instellingen">
                <TextField
                    required
                    fullWidth
                    type="text"
                    name="api/key"
                    formik={formik}
                    label={"API Token"}
                    InputProps={{
                        endAdornment: <InputAdornment position="end"><Button onClick={() => formik.setFieldValue('api/key', randomString(32))}>Genereer</Button></InputAdornment>,
                    }} />
            </SettingsCard>
        </>
    )
}
