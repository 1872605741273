import React, {FC} from "react";
import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select as MuiSelect,
    SelectChangeEvent,
    SelectProps as MuiSelectProps
} from "@mui/material";
import {useFormik} from "formik";
import ArrayHelper from "../../helpers/ArrayHelper";

export type SelectOptions = Array<{label: string, value: string|number}> | undefined;

export type SelectFieldProps = MuiSelectProps & {
    formik: ReturnType<typeof useFormik> | any, // Typed as OR any because of missing typing
    name: string,
    options: SelectOptions,
    allowEmpty?: boolean,
    valueParser?: (value: string) => any,
};

export const SelectField: FC<SelectFieldProps> = ({allowEmpty, formik, name, label, options, onChange, valueParser, required, ...restProps}) => {
    const value = ArrayHelper.get(formik.values, name);

    const handleChange = (e: SelectChangeEvent) => {
        if (valueParser) {
            formik.setFieldValue(e.target.name, valueParser(e.target.value));
        } else {
            if (e.target.value === "") {
                formik.setFieldValue(e.target.name, null);
            }
            else {
                formik.handleChange(e);
            }
        }

        onChange && onChange(e, null);
    };

    return (
        <FormControl fullWidth error={formik.touched[name] && Boolean(formik.errors[name])} margin={"normal"}>
            <InputLabel id={`${name}-label`} sx={{marginLeft: '-12px'}}>{label || name} {required && <span className={"MuiFormLabel-asterisk"}>*</span>}</InputLabel>
            <MuiSelect
                id={name}
                name={name}
                label={label || name}
                labelId={`${name}-label`}
                value={value}
                onChange={handleChange}
                variant="standard"
                required={required}
                {...restProps}
            >
                {allowEmpty === true && <MenuItem value={""}><em><strong>x Wis selectie</strong></em></MenuItem>}
                {options && options.map((option) => <MenuItem key={`${name}-${option.value}`} value={option.value}>{option.label}</MenuItem>)}
            </MuiSelect>
            {formik.touched[name] &&
                <FormHelperText>{formik.errors[name] as string}</FormHelperText>
            }
        </FormControl>
    );
}
