import React, {FC} from "react";

type DeliveryTrackingLinksRendererProps = {
    links: Array<string>,
}

export const DeliveryTrackingLinksRenderer: FC<DeliveryTrackingLinksRendererProps> = ({links}) => {
    return (
        <div>
            {links.map((link, index) => (
                <><a href={link} target={'_blank'} key={index}>{link}</a><br /></>
            ))}
        </div>
    );
}
