import api from "./api";
import DynamicListRequest from "../../types/api/requests/DynamicListRequest";
import UrlHelper from "../../helpers/UrlHelper";
import {HandlingCostGroupsResponse} from "../../types/api/responses/handling-cost-groups/HandlingCostGroupsResponse";
import {HandlingCostGroupResponse} from "../../types/api/responses/handling-cost-groups/HandlingCostGroupResponse";
import {HandlingCostGroupRequest} from "../../types/api/requests/handling-cost-group/HandlingCostGroupRequest";

const handlingCostGroupApi = api.injectEndpoints({
    endpoints: build => ({
        getHandlingCostGroups: build.query<HandlingCostGroupsResponse, DynamicListRequest>({
            query: (request) => UrlHelper.buildUrl('api/handling_cost_groups', request),
            providesTags: ['handling-cost-groups'],
        }),

        getHandlingCostGroup: build.query<HandlingCostGroupResponse, string>({
            query: (url) => ({
                url: url,
                method: 'GET',
            }),
            providesTags: ['handling-cost-groups']
        }),

        createHandlingCostGroup: build.mutation<HandlingCostGroupResponse, HandlingCostGroupRequest>({
            query: (body) => ({
                url: '/api/handling_cost_groups',
                method: 'POST',
                body: body,
            }),
            invalidatesTags: ['handling-cost-groups']
        }),

        updateHandlingCostGroup: build.mutation<HandlingCostGroupResponse, HandlingCostGroupRequest>({
            query: (body) => ({
                url: `/api/handling_cost_groups/${body.id}`,
                method: 'PUT',
                body: body,
            }),
            invalidatesTags: ['handling-cost-groups']
        }),

        deleteHandlingCostGroup: build.mutation<boolean, number>({
            query: (id) => ({
                url: `/api/handling_cost_groups/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['handling-cost-groups']
        }),
    }),
});

export const {
    useGetHandlingCostGroupsQuery,
    useCreateHandlingCostGroupMutation,
    useUpdateHandlingCostGroupMutation,
    useDeleteHandlingCostGroupMutation,
    useGetHandlingCostGroupQuery,
} = handlingCostGroupApi;

export default handlingCostGroupApi;
