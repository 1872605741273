import React, {Children, cloneElement, FC, PropsWithChildren, ReactElement, ReactNode} from "react";
import {AppAccordion} from "../ui/AppAccordion";

type FilterProps = PropsWithChildren<{}>;

export const Filter: FC<FilterProps> = ({children}) => {
    const arrayOfChildren = Children.toArray(children);
    const formattedChildren = arrayOfChildren.map((child: ReactNode, index: number) => {
        if (child) {
            const newChild = cloneElement((child as ReactElement), {style: {width: '100%'}});

            return (<div key={`filter-${index}`} style={{margin: '10px 10px 0 0', width: '19%'}}>{newChild}</div>);
        }
    });

    return (
        <div style={{marginBottom: '20px', width: '100%'}}>
            <AppAccordion title="Filter">
                <div style={{flex: 1, display: 'flex', flexWrap: 'wrap'}}>
                    {formattedChildren}
                </div>
            </AppAccordion>
        </div>
    );
}
