import React, {FC, useEffect, useState} from "react";
import {Alert, AlertColor, Box, Skeleton, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import QRCode from "react-qr-code";
import {AppLink} from "../../components/ui/AppLink";
import {LoadingButton} from "../../components/form/LoadingButton";
import useQuery from "../../hooks/useQuery";
import {TextField} from "../../components/form/TextField";
import {PublicLayout} from "../../components/layout/PublicLayout";
import {
    useLoginMutation,
    useSetupTwoFactorAuthenticationMutation,
    useValidateTwoFactorCodeMutation
} from "../../redux/api/auth";
import {FormikHelpers, useFormik} from "formik";
import ApiErrorHelper from "../../helpers/ApiErrorHelper";
import {showSnackbar} from "../../redux/slices/layout";
import {store} from "../../redux/store";

export const TwoFactorSetupPage: FC<{}> = () => {
    const navigate = useNavigate();
    const [setupTwoFactorAuthentication, {isLoading: isSettingUpTwoFactor}] = useSetupTwoFactorAuthenticationMutation();
    const [validateTwoFactorCode, {isLoading: isValidatingTwoFactorCode}] = useValidateTwoFactorCodeMutation();
    const [qrContent, setQrContent] = useState<string | null>(null);
    const query = useQuery();

    useEffect(() => {
        const fetchQrContent = async () => {
            const response = await setupTwoFactorAuthentication({regenerate: query.has('regenerate')}).unwrap();
            setQrContent(response.qrContent);
        }

        // if the user has already set up two-factor authentication, redirect to the authenticate page
        fetchQrContent().catch((error) => {
            if (error.originalStatus === 400) {
                store.dispatch(showSnackbar({type: 'error', message: `Je hebt al twee-factor authenticatie ingesteld, je bent doorgestuurd naar de authenticatie pagina.`}));
                navigate('/2fa/authenticate');
            }
        });

    }, []);

    const submit = async (values: any, formikHelpers: FormikHelpers<any>) => {
        try {
            await validateTwoFactorCode(values).unwrap();
            navigate('/calculations');
        } catch (err) {
            ApiErrorHelper.processErrors(err, formikHelpers);
            formikHelpers.setSubmitting(false);
        }
    };


    const formik = useFormik({
        initialValues: {
            code: null,
        },
        onSubmit: submit,
    });

    return (
        <PublicLayout>
            <Typography component="h1" variant="h5">
                Twee-factor authenticatie instellen
            </Typography>
            <Typography component="h1" variant="h6">
                <Box component="span" fontWeight="bold">Stap 1:</Box>
            </Typography>
            <Typography component="p">
                Scan de QR code met je authenticator app.
            </Typography>

            {!qrContent && <Skeleton variant="rectangular" width={200} height={200} />}
            {qrContent && <QRCode value={qrContent} size={200} />}

            <Typography component="h1" variant="h6" sx={{marginTop: 5}}>
                <Box component="span" fontWeight="bold">Stap 2:</Box>
            </Typography>
            <Typography component="p">
                Vul de code in die in de authenticator app wordt weergegeven.
            </Typography>

            <form onSubmit={formik.handleSubmit}>
                <TextField
                    margin="normal"
                    required
                    variant="outlined"
                    label={'Code'}
                    name="code"
                    formik={formik}
                    autoFocus
                />
                <LoadingButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    color={"primary"}
                    loading={isSettingUpTwoFactor || isValidatingTwoFactorCode}
                >
                    Authenticeren
                </LoadingButton>
            </form>
        </PublicLayout>
    );
}
