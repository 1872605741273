import React, {FC} from 'react';
import {useFormik} from "formik";
import {LoadableContainer} from "../../../../components/ui/LoadableContainer";
import {Alert, Checkbox, FormControlLabel, FormLabel,} from "@mui/material";
import {useGetEmailsQuery} from "../../../../redux/api/settings";
import {TextField} from "../../../../components/form/TextField";
import {WysiwygField} from "../../../../components/form/WysiwygField";
import {AppAccordion} from "../../../../components/ui/AppAccordion";

export type EmailsFormProps = {
    formik: ReturnType<typeof useFormik>
}

export const EmailsForm: FC<EmailsFormProps> = ({formik}) => {
    const {data: emails, isLoading: isEmailsLoading} = useGetEmailsQuery();

    return (
        <LoadableContainer isLoading={isEmailsLoading}>
            <>
                {emails && emails.emails.map((email) => (
                    <AppAccordion title={email.description}>
                        <FormControlLabel
                            control={<Checkbox
                                checked={formik.values[`email/${email.code}/enabled`]}
                                onChange={(event) => formik.setFieldValue(`email/${email.code}/enabled`, event.target.checked)}
                                name={`email/${email.code}/enabled`}
                            />}
                            label={'Actief'}
                        />
                        {true === formik.values[`email/${email.code}/enabled`] && (
                            <>
                                <Alert severity="info" sx={{margin: '8px 0'}}>
                                    Beschikbare variabelen:
                                    <ul>
                                {email.availableVariables.map((variable) => (
                                        <li>{`{{ ${variable.code} }}: ${variable.description}`}</li>
                                ))}
                                    </ul>
                                </Alert>

                                <TextField
                                    required
                                    fullWidth
                                    name={`email/${email.code}/subject`}
                                    formik={formik}
                                    label={"Onderwerp"} />
                                <FormLabel>Inhoud</FormLabel>
                                <WysiwygField
                                    formik={formik}
                                    name={`email/${email.code}/content`}
                                />
                            </>
                        )}
                    </AppAccordion>
                ))}
            </>
        </LoadableContainer>
    )
}
