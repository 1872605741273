import React, {FC} from "react";
import {TextFieldProps as MuiTextFieldProps} from "@mui/material";
import {useFormik} from "formik";
import {TextField} from "./TextField";
import FormValueHelper from "../../helpers/FormValueHelper";

export type PercentageFieldProps = MuiTextFieldProps & {
    formik: ReturnType<typeof useFormik> | any, // Typed as OR any because of missing typing
    name: string,
};

export const PercentageField: FC<PercentageFieldProps> = ({formik, name, label, ...restProps}) => {
    return (
        <TextField
            fullWidth
            name={name}
            formik={formik}
            label={label}
            valueParser={FormValueHelper.parseFloat}
            InputProps={{
                endAdornment: '%',
            }}
            {...restProps}
        />
    );
}
