import React, {FC} from "react";
import {Button} from "@mui/material";
import {ButtonProps} from "@mui/material/Button/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

type AddButtonProps = ButtonProps & {
    title: string,
}

export const AddButton: FC<AddButtonProps> = ({title, ...restProps}) => {
    return (
        <Button variant="contained" {...restProps}>
            <AddCircleOutlineIcon sx={{marginRight: 1}} /> {title}
        </Button>
    );
}
