import React, {FC} from "react";
import {Alert, AlertColor, Box, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {AppLink} from "../../components/ui/AppLink";
import {LoadingButton} from "../../components/form/LoadingButton";
import useQuery from "../../hooks/useQuery";
import {TextField} from "../../components/form/TextField";
import {PublicLayout} from "../../components/layout/PublicLayout";
import {useLoginMutation} from "../../redux/api/auth";
import {FormikHelpers, useFormik} from "formik";
import ApiErrorHelper from "../../helpers/ApiErrorHelper";
import useJwt from "../../hooks/useJwt";
import jwtDecode from "jwt-decode";

export const LoginPage: FC<{}> = () => {
    const navigate = useNavigate();
    const [login, {isLoading}] = useLoginMutation();
    const query = useQuery();

    const submit = async (values: any, formikHelpers: FormikHelpers<any>) => {
        try {
            const loginData = await login(values).unwrap();
            const decodedJwt: any = jwtDecode(loginData.token);

            if (decodedJwt.hasSetupTwoFactor) {
                navigate('/2fa/authenticate');
                return;
            }

            navigate('/2fa/setup');
        } catch (err) {
            ApiErrorHelper.processErrors(err, formikHelpers);
            formikHelpers.setSubmitting(false);
        }
    };


    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        onSubmit: submit,
    });


    let alert = null;
    if (query.has('m')) {
        const messageParts = (query.get('m') as string).split('|');
        alert = <Alert severity={messageParts[0] as AlertColor}>{messageParts[1]}</Alert>;
    }

    const DynamicGreeting = () => {
        let hour = new Date().getHours();
        if (hour < 12) {
            return <>Goedemorgen!</>;
        } else if (hour < 18) {
            return <>Goedemiddag!</>;
        } else {
            return <>Goedenavond!</>;
        }
    };

    return (
        <PublicLayout>
            <Typography component="h1" variant="h5">
                <DynamicGreeting />
            </Typography>
            {alert}
            <form onSubmit={formik.handleSubmit}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    variant="outlined"
                    label={'E-mailadres'}
                    name="email"
                    formik={formik}
                    autoFocus
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    variant="outlined"
                    name="password"
                    label={'Wachtwoord'}
                    formik={formik}
                    type="password"
                />
                <LoadingButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    color={"primary"}
                    loading={isLoading}
                >
                    Inloggen
                </LoadingButton>

                <Box>
                    <AppLink to={'/forgot-password' + (formik.values.email ? '?email=' + formik.values.email : '')} variant="body2" style={{display: 'block', marginTop: 10, textAlign: 'right'}}>
                       Wachtwoord vergeten?
                    </AppLink>
                </Box>
            </form>
        </PublicLayout>
    );
}
