import React, {FC, PropsWithChildren, ReactNode} from "react";
import {Grid} from "@mui/material";
import logo from '../../assets/images/logo-v3.svg';
import loginImage from "../../assets/images/login.jpg";
import {Snackbar} from "../ui/Snackbar";

type PublicLayoutProperties = PropsWithChildren<{
    extraLogo?: ReactNode | undefined,
}>;

export const PublicLayout: FC<PublicLayoutProperties> = ({ children, extraLogo }) => {
    return (
        <>
            <Grid container spacing={0}>
                <Grid item md={6}>
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <img src={logo} alt="Ecommerce Management System Logo" style={{margin: '37px 0 0 45px', width: '180px'}} />
                            {extraLogo && (
                                <span style={{marginLeft: '20px', borderLeft: '1px solid lightgrey', display: 'inline-block'}}>{extraLogo}</span>
                            )}
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        style={{ minHeight: '80vh' }}
                    >
                        <Grid item xs={6} style={{width: '60%'}}>
                            {children}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={6} sx={{ display: { xs: 'none', md: 'block' } }} style={{background: 'transparent url('+loginImage+') 0% 0% no-repeat padding-box', backgroundSize: 'cover', height: '100vh', width: '100%'}} />
            </Grid>
            <Snackbar />
        </>
    );
}
