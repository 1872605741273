import React, {FC, useRef, useState} from 'react';
import {PrivateLayout} from "../../../components/layout/PrivateLayout";
import DynamicDataGrid, {DynamicDataGridHandle} from "../../../components/datagrid/DynamicDataGrid";
import {
    useDeleteUserMutation,
    useGetUserCollectionQuery,
    useRequestPasswordResetMutation
} from "../../../redux/api/user";
import {GridColDef} from "@mui/x-data-grid";
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {RowActions} from "../../../components/datagrid/RowActions";
import {roleNames} from "../../../helpers/security";
import {Grid, Typography} from "@mui/material";
import {Form as UserForm} from "./Users/Form";
import {UserResponse} from "../../../types/api/responses/user/UserResponse";
import {ConfirmDialog} from "../../../components/dialogs/ConfirmDialog";
import LockResetIcon from '@mui/icons-material/LockReset';
import {AddButton} from "../../../components/form/AddButton";
import {useResetTwoFactorAuthenticationMutation} from "../../../redux/api/auth";

export const UsersPage: FC<{}> = () => {
    const [deleteUser] = useDeleteUserMutation();
    const [requestPasswordReset] = useRequestPasswordResetMutation();
    const [resetTwoFactorAuthentication] = useResetTwoFactorAuthenticationMutation();
    const [formOpen, setFormOpen] = useState(false);
    const [selectedForEditing, setSelectedForEditing] = useState<UserResponse|null>(null);
    const [selectedForDeleting, setSelectedForDeleting] = useState<UserResponse|null>(null);
    const [selectedForPasswordReset, setSelectedForPasswordReset] = useState<UserResponse|null>(null);
    const [selectedForTwoFactorReset, setSelectedForTwoFactorReset] = useState<UserResponse|null>(null);
    const gridRef = useRef<DynamicDataGridHandle|null>(null);

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'name', headerName: 'Naam', flex: 1 },
        { field: 'email', headerName: 'E-mail', flex: 1 },
        {
            field: 'role',
            headerName: 'Rol',
            flex: 1,
            renderCell: (params) => roleNames[params.row.role],
        },
        {
            field: 'actions',
            headerName: 'Acties',
            flex: 1,
            renderCell: (params) => {
                if(params.row.role === 'ROLE_SYSTEM') {
                    return null;
                }

                const actions = [
                    {
                        icon: <EditIcon/>,
                        label: 'Wijzigen',
                        onClick: () => {
                            setSelectedForEditing(params.row);
                            setFormOpen(true);
                        }
                    },
                    {
                        icon: <LockResetIcon/>,
                        label: 'Wachtwoord resetten',
                        onClick: async () => {
                            setSelectedForPasswordReset(params.row);
                        }
                    },
                    {
                        icon: <LockResetIcon/>,
                        label: '2FA resetten',
                        onClick: async () => {
                            setSelectedForTwoFactorReset(params.row);
                        }
                    },
                    {
                        icon: <DeleteForeverIcon/>,
                        label: 'Verwijderen',
                        onClick: async () => {
                            setSelectedForDeleting(params.row);
                        }
                    },
                ];

                return <RowActions actions={actions}/>;
            },
        },
    ];

    const handleCreateUserClick = () => {
        setSelectedForEditing(null);
        setFormOpen(true);
    }

    const handleCloseFormDialog = () => {
        setFormOpen(false);
        gridRef.current?.reload();
    }

    const handleResetPasswordDecline = () => {
        setSelectedForPasswordReset(null);
    }

    const handleResetPasswordConfirm = async () => {
        if (selectedForPasswordReset) {
            await requestPasswordReset({email: selectedForPasswordReset?.email as string}).unwrap();
            setSelectedForPasswordReset(null);
        }
    }

    const handleResetTwoFactorDecline = () => {
        setSelectedForTwoFactorReset(null);
    }

    const handleResetTwoFactorConfirm = async () => {
        if (selectedForTwoFactorReset) {
            await resetTwoFactorAuthentication({email: selectedForTwoFactorReset?.email as string}).unwrap();
            setSelectedForTwoFactorReset(null);
        }
    }

    const handleDeleteConfirm = async () => {
        if (selectedForDeleting) {
            await deleteUser(selectedForDeleting.id).unwrap();
            setSelectedForDeleting(null);
            gridRef.current?.reload();
        }
    }

    const handleDeleteDecline = () => {
        setSelectedForDeleting(null);
    }

    return (
        <PrivateLayout>
            <Grid container>
                <Grid item xs={12} sm={6} mb={3}>
                    <Typography component="h1" variant="h5">
                        Gebruikersbeheer
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} textAlign={'right'}>
                    <AddButton title="Gebruiker toevoegen" onClick={handleCreateUserClick} />
                </Grid>

                <DynamicDataGrid
                    ref={gridRef}
                    columns={columns}
                    queryHook={useGetUserCollectionQuery}
                    autoHeight={true}
                    checkboxSelection={false}
                    disableRowSelectionOnClick={true}
                />
            </Grid>

            <UserForm
                action={null === selectedForEditing ? 'create' : 'edit'}
                open={formOpen}
                close={handleCloseFormDialog}
                initialValues={selectedForEditing}
            />

            {selectedForPasswordReset && (
                <ConfirmDialog
                    title={'Wachtwoord resetten'}
                    message={`De gebruiker "${selectedForPasswordReset.name}" zal een e-mail ontvangen met instructies om het wachtwoord te resetten. Weet je zeker dat je door wilt gaan?`}
                    onYes={handleResetPasswordConfirm}
                    onNo={handleResetPasswordDecline}
                />
            )}

            {selectedForTwoFactorReset && (
                <ConfirmDialog
                    title={'2FA resetten'}
                    message={`Weet je zeker dat je de 2FA van gebruiker "${selectedForTwoFactorReset.name}" wilt resetten? De gebruiker zal tijdens de volgende login opnieuw de 2FA moeten instellen.`}
                    onYes={handleResetTwoFactorConfirm}
                    onNo={handleResetTwoFactorDecline}
                />
            )}

            {selectedForDeleting && (
                <ConfirmDialog
                    title={'Gebruiker verwijderen'}
                    message={`Weet je zeker dat je gebruiker "${selectedForDeleting.name}" wilt verwijderen?`}
                    onYes={handleDeleteConfirm}
                    onNo={handleDeleteDecline}
                />
            )}

        </PrivateLayout>
    );
}

