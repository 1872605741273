import React, {FC} from "react";
import {Button, LinearProgress} from "@mui/material";
import {ButtonProps} from "@mui/material/Button/Button";

type LoadingButtonProps = ButtonProps & {
    loading?: boolean;
}

export const LoadingButton: FC<LoadingButtonProps> = ({loading, children, ...restProps}) => {
    return (
        <Button disabled={loading} {...restProps}>
            {children}
            {loading && <LinearProgress
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                }}
            />}
        </Button>
    );
};
