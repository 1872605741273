import api from "./api";
import {UsersResponse} from "../../types/api/responses/user/UsersResponse";
import DynamicListRequest from "../../types/api/requests/DynamicListRequest";
import {UserResponse} from "../../types/api/responses/user/UserResponse";
import UrlHelper from "../../helpers/UrlHelper";
import {UserRequest} from "../../types/api/requests/user/UserRequest";

const userApi = api.injectEndpoints({
    endpoints: build => ({
        getUserCollection: build.query<UsersResponse, DynamicListRequest>({
            query: (request) => UrlHelper.buildUrl('/api/users', request),
        }),

        getMe: build.query<UserResponse, void>({
            query: () => '/api/users/me',
        }),

        getUser: build.query<UserResponse, number>({
            query: (id) => `/api/users/${id}`,
        }),

        createUser: build.mutation<UserResponse, UserRequest>({
            query: (user) => ({
                url: '/api/users',
                method: 'POST',
                body: user,
            })
        }),

        updateUser: build.mutation<UserResponse, UserRequest>({
            query: (user) => ({
                url: `/api/users/${user.id}`,
                method: 'PUT',
                body: user,
            })
        }),

        deleteUser: build.mutation<boolean, number>({
            query: (id) => ({
                url: `/api/users/${id}`,
                method: 'DELETE',
            })
        }),

        requestPasswordReset: build.mutation<boolean, {email: string}>({
            query: (data) => ({
                url: `/api/public/users/request-password-reset`,
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    "content-type": "application/ld+json"
                }
            })
        }),

        resetPassword: build.mutation<boolean, {actionToken: string, password: string}>({
            query: (data) => ({
                url: `/api/public/users/reset-password`,
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    "content-type": "application/ld+json"
                }
            })
        }),
    }),
});

export const {
    useGetUserCollectionQuery,
    useGetMeQuery,
    useGetUserQuery,
    useCreateUserMutation,
    useUpdateUserMutation,
    useDeleteUserMutation,
    useRequestPasswordResetMutation,
    useResetPasswordMutation,
} = userApi;

export default userApi;
