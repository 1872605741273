import {createSlice, Draft, PayloadAction} from '@reduxjs/toolkit';

export interface DataGridState {
    selected: Array<string | number>
}

const initialState: DataGridState = {
    selected: [],
};

export const dataGridSlice = createSlice({
    name: 'dataGrid',
    initialState,
    reducers: {
        setDataGridSelection: (state: Draft<DataGridState>, action: PayloadAction<Array<string | number>>) => {
            state.selected = action.payload;
        },
    },
    // extraReducers: (builder) => {
    //     builder.addMatcher(
    //         authApi.endpoints.login.matchFulfilled,
    //         (state: Draft<DataGridState>, { payload }) => {
    //             state = initialState;
    //         }
    //     );
    // }
});

export const { setDataGridSelection } = dataGridSlice.actions;

export default dataGridSlice.reducer;
