import React, {FC} from "react";

type DeliveryCustomerRendererProps = {
    customer: any,
}

export const DeliveryCustomerRenderer: FC<DeliveryCustomerRendererProps> = ({customer}) => {
    return (
        <div>
            <span>{customer.name}</span><br />
            <small>{customer.email}</small>
        </div>
    );
}
