import KeyValue from "../types/KeyValue";

export const roleHierarchy: KeyValue<Array<string>> = {
    'ROLE_USER': ['ROLE_USER'],
    'ROLE_ADMIN': ['ROLE_ADMIN', 'ROLE_USER'],
    'ROLE_SUPER_ADMIN': ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_USER'],
};

export const roleNames: KeyValue<string> = {
    'ROLE_USER': 'Gebruiker',
    'ROLE_ADMIN': 'Beheerder',
    'ROLE_SUPER_ADMIN': 'Super Beheerder',
};

/**
 * Check if user has role according to the hierarchy
 *
 * @param role
 * @param userRoles
 */
export const userHasRole = (role: string, userRoles: Array<string>): boolean => {
    for (let i = 0; i < userRoles.length; i++) {
        if (!roleHierarchy[userRoles[i]]) {
            console.error('Role ' + userRoles[i] + ' does not exist in role hierarchy');
            continue;
        }

        if (roleHierarchy[userRoles[i]].includes(role)) {
            return true;
        }
    }

    return false;
};
