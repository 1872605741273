import React, {FC} from "react";
import {Breadcrumbs as MUIBreadcrumbs, Typography} from "@mui/material";
import {useAppSelector} from "../../../hooks/useAppSelector";
import {Breadcrumb} from "../../../redux/slices/layout";
import {AppLink} from "../../ui/AppLink";

export const Breadcrumbs: FC<{}> = () => {
    return null;
    const breadcrumbs: Breadcrumb[] = useAppSelector(state => state.layout.breadcrumbs);
    const crumbElements = breadcrumbs.map(({to, title}, key) => (
        key < breadcrumbs.length - 1
            ? <AppLink key={key} to={to}>{title}</AppLink>
            : <Typography key={key}>{title}</Typography>
    ));

    return (
        <MUIBreadcrumbs aria-label="breadcrumb" style={{marginBottom: '30px'}}>
            {crumbElements.length > 0
                ? <AppLink key="home" to="/dashboard">Home</AppLink>
                : <Typography>Home</Typography>}

            {crumbElements}
        </MUIBreadcrumbs>
    );
}
