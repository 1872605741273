import api from "./api";

const calculationQuestionOptionPriceTiersApi = api.injectEndpoints({
    endpoints: build => ({
        deleteCalculationQuestionOptionPriceTier: build.mutation<boolean, number>({
            query: (id) => ({
                url: `/api/calculation_question_options_price_tiers/${id}`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const {
    useDeleteCalculationQuestionOptionPriceTierMutation,
} = calculationQuestionOptionPriceTiersApi;

export default calculationQuestionOptionPriceTiersApi;
