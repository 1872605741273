import React, {FC} from "react";
import {Alert, AlertColor, Box, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {AppLink} from "../../components/ui/AppLink";
import {LoadingButton} from "../../components/form/LoadingButton";
import useQuery from "../../hooks/useQuery";
import {TextField} from "../../components/form/TextField";
import {PublicLayout} from "../../components/layout/PublicLayout";
import {FormikHelpers, useFormik} from "formik";
import ApiErrorHelper from "../../helpers/ApiErrorHelper";
import {useDispatch} from "react-redux";
import {useValidateTwoFactorCodeMutation} from "../../redux/api/auth";

export const TwoFactorAuthenticationPage: FC<{}> = () => {
    const navigate = useNavigate();
    const [validateTwoFactorCode, {isLoading: isValidatingTwoFactorCode}] = useValidateTwoFactorCodeMutation();
    const query = useQuery();
    const dispatch = useDispatch();

    const submit = async (values: any, formikHelpers: FormikHelpers<any>) => {
        try {
            const responseData = await validateTwoFactorCode(values).unwrap();
            navigate('/calculations');
        } catch (err) {
            ApiErrorHelper.processErrors(err, formikHelpers);
            formikHelpers.setSubmitting(false);
        }
    };


    const formik = useFormik({
        initialValues: {
            code: null,
        },
        onSubmit: submit,
    });

    return (
        <PublicLayout>
            <Typography component="h1" variant="h5">
                Twee-factor authenticatie
            </Typography>
            <Typography component="p">
                Vul de code in die in de authenticator app wordt weergegeven.
            </Typography>
            <form onSubmit={formik.handleSubmit}>
                <TextField
                    margin="normal"
                    required
                    variant="outlined"
                    label={'Code'}
                    name="code"
                    formik={formik}
                    autoFocus
                />
                <LoadingButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    color={"primary"}
                    loading={isValidatingTwoFactorCode}
                >
                    Authenticeren
                </LoadingButton>
            </form>
        </PublicLayout>
    );
}
