import {Card, CardContent, Divider, Typography} from "@mui/material";
import React, {FC, PropsWithChildren} from "react";

export type SettingsCardProps = PropsWithChildren & {
    title: string
}

export const SettingsCard: FC<SettingsCardProps> = ({title, children}) => {
    return (
        <Card variant={'outlined'} sx={{marginBottom: 3}}>
            <CardContent>
                <Typography sx={{ fontSize: 16, fontWeight: 'bold' }} color="text.secondary" gutterBottom>
                    {title}
                </Typography>
                <Divider />
                {children}
            </CardContent>
        </Card>
    );
}
