import {Box, Button} from '@mui/material';
import * as React from "react";
import {FC} from "react";
import {AppDialog, AppDialogProps} from "./AppDialog";

type ConfirmDialogProps = Omit<AppDialogProps, 'open'|'onClose'> & {
    message: string;
    onYes: () => void;
    onNo: () => void;
}

export const ConfirmDialog: FC<ConfirmDialogProps> = ({title, message, onNo, onYes}) => {
    return (
        <AppDialog open={true} onClose={onNo} title={title}>
            { message }
            <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 2}}>
                <Button autoFocus onClick={onNo} variant={'outlined'} sx={{marginRight: 1}}>Nee</Button>
                <Button variant="contained" onClick={onYes}>Ja</Button>
            </Box>
        </AppDialog>
    );
}
