import React, {FC, useEffect, useRef} from "react";
import {FormDialog} from "../../../components/dialogs/FormDialog";
import {FormikHelpers, useFormik} from "formik";
import ApiErrorHelper from "../../../helpers/ApiErrorHelper";
import {TextField} from "../../../components/form/TextField";
import NotificationHelper from "../../../helpers/NotificationHelper";
import {Box, Typography} from "@mui/material";
import {RepeatableField} from "../../../components/form/RepeatableField";
import {useGetSuppliersQuery} from "../../../redux/api/suppliers";
import {SelectField} from "../../../components/form/SelectField";
import {LoadableContainer} from "../../../components/ui/LoadableContainer";
import {
    useCreateHandlingCostGroupMutation,
    useUpdateHandlingCostGroupMutation
} from "../../../redux/api/handling-cost-groups";
import {MoneyField} from "../../../components/form/MoneyField";
import {AutocompleteField} from '../../../components/form/AutocompleteField';

export type HandlingCostGroupFormProps = {
    action: 'create' | 'edit',
    open: boolean,
    close: () => void,
    initialValues?: any,
}

export const HandlingCostGroupForm: FC<HandlingCostGroupFormProps> = ({action, initialValues, open, close}) => {
    const [createHandlingCostGroup, {isLoading: isCreating}] = useCreateHandlingCostGroupMutation();
    const [updateHandlingCostGroup, {isLoading: isUpdating}] = useUpdateHandlingCostGroupMutation();
    const {data: suppliers, isLoading: isSuppliersLoading} = useGetSuppliersQuery({});
    const dialogRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        setTimeout(() => {
            dialogRef.current?.querySelector('.MuiPaper-root')?.scrollTo({top: 0});
        })
    }, [open, dialogRef.current]);

    const submit = async (values: any, formikHelpers: FormikHelpers<any>) => {
        try {
            if (action === 'create') {
                await createHandlingCostGroup(values).unwrap();
                NotificationHelper.showSuccess('De toeslag groep is toegevoegd.');
            } else {
                await updateHandlingCostGroup(values).unwrap();
                NotificationHelper.showSuccess('De toeslag groep is gewijzigd.');
            }
            close();
        } catch (err) {
            if (err instanceof Object && 'data' in err) {
                const errorData = (err as { data: any }).data;

                if (errorData && errorData['hydra:description']) {
                    NotificationHelper.showError(errorData['hydra:description']);
                } else {
                    ApiErrorHelper.processErrors(err, formikHelpers);
                }
            } else {
                ApiErrorHelper.processErrors(err, formikHelpers);
            }

            formikHelpers.setSubmitting(false);
        }
    };

    const formik = useFormik({
        initialValues: initialValues || {},
        onSubmit: submit,
        enableReinitialize: true,
    });

    return (
        <FormDialog
            open={open}
            close={close}
            formik={formik}
            ref={dialogRef}
            isSubmitting={isCreating || isUpdating}
            handleSubmit={formik.handleSubmit}
            title={`Toeslag groep ${action === 'create' ? 'toevoegen' : 'aanpassen'}`}
        >
            <LoadableContainer isLoading={isSuppliersLoading}>
                <TextField
                    required
                    fullWidth
                    name="name"
                    formik={formik}
                    label={"Naam"} />

                {suppliers && (
                    <AutocompleteField
                        sx={{mt: 2}}
                        options={suppliers['hydra:member'].map(supplier => ({value: supplier['@id'], label: supplier.name}))}
                        formik={formik}
                        name="supplier"
                        label="Leverancier"/>
                )}

                <Box sx={{mt: 5}}>
                    <Typography variant={"h6"}>Staffels</Typography>
                    <RepeatableField
                        baseName={'handlingCostGroupTiers'}
                        formik={formik}
                        columns={[
                            {label: 'Vanaf aantal', name: 'quantity', field: 'text'},
                            {label: 'Toeslag per item', name: 'handlingCostPerItem', field: MoneyField},
                        ]}
                    />
                </Box>
            </LoadableContainer>
        </FormDialog>
    );
}
