import {store} from "../redux/store";
import {FormikHelpers} from "formik";
import {showSnackbar} from "../redux/slices/layout";

/**
 * Helper class for API Errors
 */
class ApiErrorHelper
{
    /**
     * @param error
     * @param formikHelpers
     */
    public processErrors(error: any, formikHelpers: FormikHelpers<any>): void
    {
        switch(error.status) {
            case 'FETCH_ERROR':
            case 'PARSING_ERROR':
            case 'CUSTOM_ERROR':
                store.dispatch(showSnackbar({type: 'error', message: `Er was een fout bij het ophalen van de gegevens. Probeer het later nog eens: ${error.error}`}));
                break;

            case 401:
                store.dispatch(showSnackbar({type: 'error', message: error.data.message}));
                break;

            case 403:
                store.dispatch(showSnackbar({type: 'error', message: 'Je hebt geen toegang tot deze bron.'}));
                break;

            case 422:
                this.processValidationErrors(error.data, formikHelpers);
                break;

            default:
                if (error.data && error.data['hydra:description']) {
                    store.dispatch(showSnackbar({type: 'error', message: error.data['hydra:description']}));
                }
                console.error(error.status);
                console.error(error.data);
                console.error(error);
                break;
        }
    }

    /**
     * @param data
     * @param formikHelpers
     * @private
     */
    private processValidationErrors(data: any, formikHelpers: FormikHelpers<any>): void
    {
        data.violations.forEach((violation: any) => {
            formikHelpers.setFieldError(violation.propertyPath, violation.message);
        });
    }
}

export default new ApiErrorHelper() as ApiErrorHelper;
