import React, {FC, PropsWithChildren} from "react";
import {CircularProgress, Grid} from "@mui/material";

type LoadableContainerProps = PropsWithChildren<{
    isLoading: boolean,
}>

export const LoadableContainer: FC<LoadableContainerProps> = ({isLoading, children}) => {
    return (
        <>
            {isLoading && (
                <Grid container justifyContent="center" alignContent="center" height="100%" width="100%">
                    <CircularProgress size={60} />
                </Grid>
            )}
            {!isLoading && <>{children}</>}
        </>
    );
}
