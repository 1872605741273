import React, {FC, ReactNode} from "react";
import {GridToolbarContainer} from "@mui/x-data-grid";
import {useAppSelector} from "../../hooks/useAppSelector";
import {Button, ListItemIcon, ListItemText, Menu, MenuItem} from "@mui/material";
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

export type DataGridToolbarAction = {
    icon?: ReactNode,
    label: string,
    onClick: () => void,
};

type DataGridToolbarProps = {
    actions?: Array<DataGridToolbarAction>,
    style?: React.CSSProperties,
};

export const DataGridToolbar: FC<DataGridToolbarProps> = ({actions, style}) => {
    const selectedRows = useAppSelector(state => state.datagrid.selected);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <GridToolbarContainer style={style}>
            {actions && 0 < actions.length && (
                <>
                    <Button disabled={0 === selectedRows.length} onClick={handleClick} size="small" variant="contained" sx={{ ml: 1, mr: 2 }}>
                        <KeyboardArrowDownOutlinedIcon sx={{mr: 1}} /> Bulk acties
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                    >
                        {actions.map(action =>
                            <MenuItem key={action.label} onClick={() => action.onClick()}>
                                {action.icon && (
                                    <ListItemIcon>
                                        {action.icon}
                                    </ListItemIcon>
                                )}
                                <ListItemText>{action.label}</ListItemText>
                            </MenuItem>
                        )}
                    </Menu>
                </>
            )}
            {/*<GridToolbarExport title={t('export')} variant="contained" />*/}
        </GridToolbarContainer>
    );
}
