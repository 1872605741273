import api from "./api";
import DynamicListRequest from "../../types/api/requests/DynamicListRequest";
import UrlHelper from "../../helpers/UrlHelper";
import {CalculationQuestionsResponse} from "../../types/api/responses/calculation/CalculationQuestionsResponse";
import {CalculationQuestionResponse} from "../../types/api/responses/calculation/CalculationQuestionResponse";


const calculationQuestionsApi = api.injectEndpoints({
    endpoints: build => ({
        getCalculationQuestions: build.query<CalculationQuestionsResponse, DynamicListRequest>({
            query: (request) => UrlHelper.buildUrl('api/calculation_questions', request),
            providesTags: ['calculation-questions'],
        }),

        createCalculationQuestion: build.mutation<CalculationQuestionResponse, any>({
            query: (calculationQuestion) => ({
                url: '/api/calculation_questions',
                method: 'POST',
                body: calculationQuestion,
            }),
            invalidatesTags: ['calculation-questions', 'calculations'],
        }),

        updateCalculationQuestion: build.mutation<CalculationQuestionResponse, {id: number, body: any}>({
            query: (request) => ({
                url: `/api/calculation_questions/${request.id}`,
                method: 'PUT',
                body: {...request.body},
            }),
            invalidatesTags: ['calculation-questions', 'calculations'],
        }),

        deleteCalculationQuestion: build.mutation<boolean, number>({
            query: (id) => ({
                url: `/api/calculation_questions/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['calculation-questions', 'calculations'],
        }),

        bulkActivateCalculationQuestions: build.mutation<void, Array<number>>({
            query: (request) => ({
                url: 'api/calculation_questions/activate',
                method: 'PUT',
                body: {calculationQuestionIds: request},
            }),
        }),

        bulkDeactivateCalculationQuestions: build.mutation<void, Array<number>>({
            query: (request) => ({
                url: 'api/calculation_questions/deactivate',
                method: 'PUT',
                body: {calculationQuestionIds: request},
            }),
        }),
    }),
});

export const {
    useGetCalculationQuestionsQuery,
    useCreateCalculationQuestionMutation,
    useUpdateCalculationQuestionMutation,
    useDeleteCalculationQuestionMutation,
    useBulkActivateCalculationQuestionsMutation,
    useBulkDeactivateCalculationQuestionsMutation,
} = calculationQuestionsApi;

export default calculationQuestionsApi;
