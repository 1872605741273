import React, {FC} from "react";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

type BooleanRendererProps = {
    value: boolean,
}

export const BooleanRenderer: FC<BooleanRendererProps> = ({value}) => {
    return value ? <CheckOutlinedIcon color="success" /> : <CloseOutlinedIcon color="error" />;
}
