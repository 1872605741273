import api from "./api";
import {TaxClassesResponse} from "../../types/api/responses/extra/TaxClassesResponse";

const extraApi = api.injectEndpoints({
    endpoints: build => ({
        getMagento2TaxClasses: build.query<TaxClassesResponse, void>({
            query: () => 'api/extra/magento2_taxclasses',
        }),
    }),
});

export const {
    useGetMagento2TaxClassesQuery,
} = extraApi;

export default extraApi;
