import api from "./api";

const calculationQuestionOptionsApi = api.injectEndpoints({
    endpoints: build => ({
        deleteCalculationQuestionOption: build.mutation<boolean, number>({
            query: (id) => ({
                url: `/api/calculation_question_options/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['calculation-questions'],
        }),
    }),
});

export const {
    useDeleteCalculationQuestionOptionMutation,
} = calculationQuestionOptionsApi;

export default calculationQuestionOptionsApi;
