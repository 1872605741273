import React, {FC} from "react";
import {TextFieldProps as MuiTextFieldProps} from "@mui/material";
import {useFormik} from "formik";
import {TextField} from "./TextField";
import FormValueHelper from "../../helpers/FormValueHelper";
import EuroOutlinedIcon from "@mui/icons-material/EuroOutlined";

export type MoneyFieldProps = MuiTextFieldProps & {
    formik: ReturnType<typeof useFormik> | any, // Typed as OR any because of missing typing
    name: string,
};

export const MoneyField: FC<MoneyFieldProps> = ({formik, name, label, onChange, ...restProps}) => {
    return (
        <TextField
            fullWidth
            name={`${name}.amount`}
            formik={formik}
            onChange={(event) => {
                formik.setFieldValue(`${name}.amount`, event.target.value !== ''
                    ? event.target.value
                    : null
                );
            }}
            valueParser={FormValueHelper.parseFloat}
            label={label}
            InputProps={{
                startAdornment: <EuroOutlinedIcon sx={{color: 'gray', mr: 1, fontSize: '16px'}} />,
            }}
            {...restProps}
        />
    );
}
