import React, {FC} from 'react';
import {TextField} from "../../../../components/form/TextField";
import {useFormik} from "formik";
import {SettingsCard} from "./SettingsCard";
import {Typography} from "@mui/material";

export type GeneralFormProps = {
    formik: ReturnType<typeof useFormik>
}

export const GeneralForm: FC<GeneralFormProps> = ({formik}) => {
    return (
        <>
            <SettingsCard title="E-mail instellingen">
                <TextField
                    required
                    fullWidth
                    name="email/fromName"
                    formik={formik}
                    label={"Afzender naam"} />
                <TextField
                    required
                    fullWidth
                    name="email/fromAddress"
                    formik={formik}
                    label={"Afzender e-mailadres"} />
            </SettingsCard>
            <SettingsCard title="Track en Trace instellingen">
                <TextField
                    required
                    fullWidth
                    name="tracking/baseurl"
                    formik={formik}
                    label={"Track en Trace link voor MyParcel zendingen"} />
                <Typography variant="caption">Gebruik {'{{tracking_number}}'} op de plek waar de track en trace code moet komen.</Typography>
            </SettingsCard>
        </>
    )
}
