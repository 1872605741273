import React, {FC, useEffect, useRef} from "react";
import {FormDialog} from "../../../components/dialogs/FormDialog";
import {FormikHelpers, useFormik} from "formik";
import ApiErrorHelper from "../../../helpers/ApiErrorHelper";
import {TextField} from "../../../components/form/TextField";
import NotificationHelper from "../../../helpers/NotificationHelper";
import {useCreateMarginGroupMutation, useUpdateMarginGroupMutation} from "../../../redux/api/margin-groups";
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined';
import FormValueHelper from "../../../helpers/FormValueHelper";

export type MarginGroupFormProps = {
    action: 'create' | 'edit',
    open: boolean,
    close: () => void,
    initialValues?: any,
}

export const MarginGroupForm: FC<MarginGroupFormProps> = ({action, initialValues, open, close}) => {
    const [createMarginGroup, {isLoading: isCreating}] = useCreateMarginGroupMutation();
    const [updateMarginGroup, {isLoading: isUpdating}] = useUpdateMarginGroupMutation();
    const dialogRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        setTimeout(() => {
            dialogRef.current?.querySelector('.MuiPaper-root')?.scrollTo({top: 0});
        })
    }, [open, dialogRef.current]);

    const submit = async (values: any, formikHelpers: FormikHelpers<any>) => {
        try {
            if (action === 'create') {
                await createMarginGroup(values).unwrap();
                NotificationHelper.showSuccess('De margegroep is toegevoegd.');
            } else {
                await updateMarginGroup(values).unwrap();
                NotificationHelper.showSuccess('De margegroep is gewijzigd.');
            }
            close();
        } catch (err) {
            if (err instanceof Object && 'data' in err) {
                const errorData = (err as { data: any }).data;

                if (errorData && errorData['hydra:description']) {
                    NotificationHelper.showError(errorData['hydra:description']);
                } else {
                    ApiErrorHelper.processErrors(err, formikHelpers);
                }
            } else {
                ApiErrorHelper.processErrors(err, formikHelpers);
            }

            formikHelpers.setSubmitting(false);
        }
    };

    const formik = useFormik({
        initialValues: initialValues || {},
        onSubmit: submit,
        enableReinitialize: true,
    });

    return (
        <FormDialog
            open={open}
            close={close}
            formik={formik}
            ref={dialogRef}
            isSubmitting={isCreating || isUpdating}
            handleSubmit={formik.handleSubmit}
            title={`Margegroep ${action === 'create' ? 'toevoegen' : 'aanpassen'}`}
        >
            <TextField
                required
                fullWidth
                name="name"
                formik={formik}
                label={"Naam"} />
            <TextField
                required
                fullWidth
                name="margin"
                formik={formik}
                label={"Marge"}
                valueParser={FormValueHelper.parseFloat}
                InputProps={{
                    endAdornment: <PercentOutlinedIcon />,
                }}
            />
        </FormDialog>
    );
}
