import React, {FC} from "react";
import {useFormik} from "formik";
import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Tooltip
} from "@mui/material";
import ArrayHelper from "../../../helpers/ArrayHelper";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import {MultipleImageUploadField} from "../../../components/form/MultipleImageUploadField";
import {ImageResponse} from "../../../types/api/responses/image/ImageResponse";
import {CalculationQuestionOptionRow} from "./CalculationQuestionOptionRow";
import {useGetMagento2AttributesQuery} from "../../../redux/api/magento2";

export type CalculationQuestionOptionTableProps = {
    formik: ReturnType<typeof useFormik> | any, // Typed as OR any because of missing typing
    questionKey: string,
    questionOptionsKey: string,
    isShared: boolean,
    handlingGroup?: string,
    errors?: any,
}

export const CalculationQuestionOptionTable: FC<CalculationQuestionOptionTableProps> = ({isShared, questionKey, questionOptionsKey, formik, handlingGroup, errors}) => {
    const {data: magento2Attributes} = useGetMagento2AttributesQuery();

    const addRow = () => {
        if (!ArrayHelper.get(formik.values, questionOptionsKey, false)) {
            formik.setFieldValue(questionOptionsKey, [{}]);
        } else {
            formik.setFieldValue(questionOptionsKey, [...ArrayHelper.get(formik.values, questionOptionsKey, {}), {}]);
        }
    }

    const onImagesUploaded = (images: ImageResponse[], files: File[]) => {
        const options = [...ArrayHelper.get(formik.values, questionOptionsKey, [])];

        formik.setFieldValue(questionOptionsKey, options.concat(images.map(i => ({image: i}))));
    }

    return (
        <TableContainer component={Paper} sx={{position: "relative", overflowX: "visible"}}>
            <MultipleImageUploadField onUploaded={onImagesUploaded} sx={{position: 'absolute', top: "-40px", right: "-15px"}} />
            <Table size={"small"}>
                <TableHead>
                    <TableRow>
                        <TableCell>Actief</TableCell>
                        <TableCell>Sync</TableCell>
                        <TableCell>
                            Optie *
                        </TableCell>
                        <TableCell>
                            Sub omschrijving
                        </TableCell>
                        <TableCell>
                            SKU
                        </TableCell>
                        <TableCell>
                            Afb.
                        </TableCell>
                        {formik.values.questionType === 'color' || ArrayHelper.get(formik.values, questionKey).questionType === 'color' && magento2Attributes != undefined ?
                            <TableCell>
                                Kleur
                            </TableCell>
                        : null}
                        <TableCell>
                            Exact omschrijving
                        </TableCell>
                        <TableCell>
                            CMS Blok ID
                        </TableCell>
                        <TableCell>
                            Prijs
                        </TableCell>
                        <TableCell width={150}>
                            Minimale afname
                        </TableCell>
                        <TableCell>
                            Op voorraad
                        </TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {ArrayHelper.get(formik.values, questionOptionsKey, [])
                        && ArrayHelper.get(formik.values, questionOptionsKey, []).map((data: any, index: number) => (
                            <CalculationQuestionOptionRow
                                key={`${questionOptionsKey}.${index}`}
                                formik={formik}
                                questionKey={questionKey}
                                questionOptionsKey={questionOptionsKey}
                                rowKey={`${questionOptionsKey}.${index}`}
                                isShared={isShared}
                                handlingGroup={handlingGroup}
                            />
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={9}></TableCell>
                        <TableCell>
                            <Tooltip title={'Voeg een optie toe'}>
                                <IconButton onClick={addRow}>
                                    <AddCircleOutlineOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
};
