import React, {FC, useEffect, useRef} from "react";
import {FormDialog} from "../../../components/dialogs/FormDialog";
import {FormikHelpers, useFormik} from "formik";
import ApiErrorHelper from "../../../helpers/ApiErrorHelper";
import {TextField} from "../../../components/form/TextField";
import NotificationHelper from "../../../helpers/NotificationHelper";
import {
    useCreateOptionGroupMutation,
    useUpdateOptionGroupMutation
} from "../../../redux/api/option-groups";
import {Alert, Box, Tooltip, Typography} from "@mui/material";
import {RepeatableField} from "../../../components/form/RepeatableField";
import {useGetSuppliersQuery} from "../../../redux/api/suppliers";
import {LoadableContainer} from "../../../components/ui/LoadableContainer";
import {useGetHandlingCostGroupsQuery} from "../../../redux/api/handling-cost-groups";
import {useGetShippingTimeGroupsQuery} from "../../../redux/api/shipping-time-groups";
import {CalculationQuestionOptionTable} from "../Calculations/CalculationQuestionOptionTable";
import {CheckboxField} from '../../../components/form/CheckboxField';
import {AutocompleteField} from '../../../components/form/AutocompleteField';
import {OptionGroupLinkedCalculation} from "./OptionGroupLinkedCalculations";
import {OptionGroupResponse} from "../../../types/api/responses/option-group/OptionGroupResponse";

export type OptionGroupFormProps = {
    action: 'create' | 'edit',
    open: boolean,
    close: () => void,
    initialValues?: any,
}

export const OptionGroupForm: FC<OptionGroupFormProps> = ({action, initialValues, open, close}) => {
    const [createOptionGroup, {isLoading: isCreating}] = useCreateOptionGroupMutation();
    const [updateOptionGroup, {isLoading: isUpdating}] = useUpdateOptionGroupMutation();
    const {data: suppliers, isLoading: isSuppliersLoading} = useGetSuppliersQuery({page: 1, pageSize: 1000});
    const {data: handlingCostGroups, isLoading: isHandlingCostGroupsLoading} = useGetHandlingCostGroupsQuery({page: 1, pageSize: 1000});
    const {data: shippingTimeGroups, isLoading: isShippingTimeGroupsLoading} = useGetShippingTimeGroupsQuery({page: 1, pageSize: 1000});
    const dialogRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        setTimeout(() => {
            dialogRef.current?.querySelector('.MuiPaper-root')?.scrollTo({top: 0});
        })
    }, [open, dialogRef.current]);

    const check = () => {
        if (formik.values.options === undefined || formik.values.options.length < 1) {
            NotificationHelper.showError('Opties kunnen niet leeg zijn');
            return false;
        }

        return true;
    }

    const submit = async (values: any, formikHelpers: FormikHelpers<any>) => {
        if (!check()) {
            formikHelpers.setSubmitting(false);
            return;
        }
        try {
            if (action === 'create') {
                await createOptionGroup(values).unwrap();
                NotificationHelper.showSuccess('De optiegroep is toegevoegd.');
            } else {
                await updateOptionGroup(values).unwrap();
                NotificationHelper.showSuccess('De optiegroep is gewijzigd.');
            }
            close();
        } catch (err) {
            if (err instanceof Object && 'data' in err) {
                const errorData = (err as { data: any }).data;

                if (errorData && errorData['hydra:description']) {
                    NotificationHelper.showError(errorData['hydra:description']);
                } else {
                    ApiErrorHelper.processErrors(err, formikHelpers);
                }
            } else {
                ApiErrorHelper.processErrors(err, formikHelpers);
            }

            formikHelpers.setSubmitting(false);
        }
    };

    const formik = useFormik({
        initialValues: initialValues || {options: [], deactivatable: true},
        onSubmit: submit,
        enableReinitialize: true,
        validate: (values) => {
            const errors: Partial<OptionGroupResponse> = {};

            if (typeof values.name !== 'string' || values.name.length < 1) {
                errors.name = 'Naam is verplicht.';
            }

            return errors;
        }
    });

    const handleExactDescriptionChange = (e: any) => {
        const options = formik.values.options;

        options.forEach((option: any, index: number) => {
            formik.setFieldValue(`options[${index}].descriptionExact`,  e.target.value);
        });

    }

    return (
        <FormDialog
            fullScreen
            open={open}
            close={close}
            formik={formik}
            isConceptualizable
            ref={dialogRef}
            isSubmitting={isCreating || isUpdating}
            handleSubmit={formik.handleSubmit}
            title={`Optiegroep ${action === 'create' ? 'toevoegen' : 'aanpassen'}`}
        >
            <LoadableContainer isLoading={isSuppliersLoading || isHandlingCostGroupsLoading || isShippingTimeGroupsLoading}>
                <TextField
                    required
                    fullWidth
                    name="name"
                    formik={formik}
                    label={"Naam"} />
                <TextField
                    required
                    fullWidth
                    name="sku"
                    formik={formik}
                    label={"SKU"} />

                {suppliers && (
                    <AutocompleteField
                        sx={{mb: 2}}
                        options={suppliers['hydra:member'].map(supplier => ({value: supplier['@id'], label: supplier.name}))}
                        formik={formik}
                        name="supplier"
                        label="Leverancier"/>
                )}

                {handlingCostGroups && (
                    <AutocompleteField
                        sx={{mb: 2}}
                        options={handlingCostGroups['hydra:member'].map(group => ({value: group['@id'], label: group.name}))}
                        formik={formik}
                        name="handlingCostGroup"
                        label="Toeslag groep" />
                )}

                {shippingTimeGroups && (
                    <AutocompleteField
                        options={shippingTimeGroups['hydra:member'].map(group => ({value: group['@id'], label: group.name}))}
                        formik={formik}
                        name="shippingTimeGroup"
                        label="Levertijd toeslag groep" />
                )}

                <TextField
                    fullWidth
                    name="exactDescription"
                    formik={formik}
                    onChange={handleExactDescriptionChange}
                    label={"Algemene exact omschrijving"} />


                <Tooltip title={formik.values.deactivatable
                    ? 'Geeft aan of deze optiegroep actief is en kan worden gebruikt voor een calculatievraag. Vinkje uitzetten om te kunnen opslaan als concept.'
                    : 'Kan niet gedeactiveerd worden, verwijder eerst de calculatie-vragen die gebruik maken van deze optie-groep.'
                }>
                    <span>
                        <CheckboxField
                            name="active"
                            formik={formik}
                            label={"Actief"}
                            disabled={! formik.values.deactivatable && formik.values.active}
                        />
                    </span>
                </Tooltip>

                <Box sx={{mt: 5}}>
                    <Typography variant={"h6"}>Levertijden staffels</Typography>
                    <Alert sx={{my: '10px'}} severity="info">
                        Indien er geen leverancier is gekoppeld aan deze optiegroep, kun je hier extra
                        levertijd toevoegen op basis van het aantal dat er wordt besteld. Wanneer
                        er wel een leverancier is gekoppeld, dan worden deze levertijden bij de
                        levertijden van de leverancier opgeteld.
                    </Alert>
                    <RepeatableField
                        baseName={'deliveryTimeTiers'}
                        formik={formik}
                        columns={[
                            {label: 'Vanaf aantal', name: 'quantity', field: 'number'},
                            {label: 'Levertijd toeslag (in dagen)', name: 'deliveryTimeAddition', field: 'number'},
                        ]}
                    />
                </Box>

                <Box sx={{mt: 5}}>
                    <Typography variant={"h6"}>Opties</Typography>
                    <CalculationQuestionOptionTable
                        formik={formik}
                        questionKey={''}
                        questionOptionsKey={'options'}
                        isShared={false}
                        handlingGroup={formik.values.handlingCostGroup}
                        errors={formik.errors.options ? Object.values(formik.errors.options) : undefined}
                    />
                </Box>

                {formik.values.options.length > 0 &&
                    <Box sx={{mt: 5}}>
                        <Typography variant={"h6"}>Gekoppelde calculaties</Typography>
                        <OptionGroupLinkedCalculation
                            formik={formik}
                        />
                    </Box>
                }
            </LoadableContainer>
        </FormDialog>
    );
}
