import React, {FC, SyntheticEvent} from "react";
import {
    Autocomplete,
    AutocompleteProps,
    AutocompleteValue,
    Chip,
    createFilterOptions,
    FilterOptionsState,
    TextField
} from "@mui/material";
import {useFormik} from "formik";

export type AutocompleteOption = {
    value?: number|string,
    label: string,
    newValue?: string,
}

type AutocompleteMultipleWithNewFieldProps = Omit<AutocompleteProps<AutocompleteOption, true, false, true> & {
    formik: ReturnType<typeof useFormik> | any, // Typed as OR any because of missing typing
    name: string,
    label: string,
    options: Array<AutocompleteOption>,
    onNewEntry?: (newValue: string) => Promise<AutocompleteOption>,
}, 'renderInput'>

const filter = createFilterOptions<AutocompleteOption>();

export const AutocompleteMultipleWithNewField: FC<AutocompleteMultipleWithNewFieldProps> = ({formik, name, label, options, onNewEntry, ...restProps}) => {
    /**
     * @param event
     * @param newValue
     */
    const onChange = async (event: SyntheticEvent, newValue: AutocompleteValue<Array<AutocompleteOption|string>, false, false, false>) => {
        if (onNewEntry && newValue) {
            for (let i = 0; i < newValue.length; i++) {
                if (typeof newValue[i] === 'string') {
                    newValue[i] = await onNewEntry(newValue[i] as string);
                    continue;
                }

                const option = newValue[i] as AutocompleteOption;
                if (option.newValue) {
                    newValue[i] = await onNewEntry(option.newValue);
                }
            }
        }

        formik.setFieldValue(name, newValue);
    }

    /**
     * @param option
     */
    const onGetOptionLabel = (option: AutocompleteOption | string) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
            return option;
        }
        if (option.newValue) {
            return `Voeg "${option.newValue}" toe`;
        }

        return option.label;
    }

    /**
     * @param options
     * @param params
     */
    const onFilterOptions = (options: Array<AutocompleteOption>, params: FilterOptionsState<AutocompleteOption>) => {
        const filtered = filter(options, params);
        const { inputValue } = params;

        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.label);
        if (inputValue !== '' && !isExisting) {
            filtered.push({
                newValue: inputValue,
                label: inputValue,
            });
        }

        return filtered;
    }

    return (
        <Autocomplete
            multiple
            freeSolo
            selectOnFocus={true}
            clearOnBlur={true}
            handleHomeEndKeys={true}
            filterSelectedOptions={true}
            options={options}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            value={formik.values[name] || []}
            onChange={onChange}
            getOptionLabel={onGetOptionLabel}
            filterOptions={onFilterOptions}
            renderTags={(value, getTagProps) =>
                value.map((option: AutocompleteOption, index: number) => {
                    if (!option) return null;
                    return <Chip variant="outlined" label={option.label} {...getTagProps({index})} />;
                })
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    label={label}
                    placeholder={label}
                />
            )}
            {...restProps}
        />
    );
}
