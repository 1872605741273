class FormValueHelper
{
    public parseFloat(value: string): number|string
    {
        if (value.endsWith(',') || value.endsWith('.') || value.endsWith(',0') || value.endsWith('.0')) {
            return value;
        }

        if (0 === parseFloat(value.replace(',', '.'))) {
            return value;
        }

        return parseFloat(value.replace(',', '.'));
    }
}

export default new FormValueHelper();
