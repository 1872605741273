import api from "./api";
import DynamicListRequest from "../../types/api/requests/DynamicListRequest";
import UrlHelper from "../../helpers/UrlHelper";
import {CalculationTagsResponse} from "../../types/api/responses/calculation/CalculationTagsResponse";
import {CalculationTagResponse} from "../../types/api/responses/calculation/CalculationTagResponse";
import {CalculationTagRequest} from "../../types/api/requests/calculation-tag/CalculationTagRequest";

const calculationTagsApi = api.injectEndpoints({
    endpoints: build => ({
        getCalculationTags: build.query<CalculationTagsResponse, DynamicListRequest>({
            query: (request) => UrlHelper.buildUrl('api/calculation_tags', request),
            providesTags: ['calculation-tags'],
        }),

        createCalculationTag: build.mutation<CalculationTagResponse, CalculationTagRequest>({
            query: (calculationTag) => ({
                url: '/api/calculation_tags',
                method: 'POST',
                body: calculationTag,
            }),
            invalidatesTags: ['calculation-tags']
        }),
    }),
});

export const {
    useGetCalculationTagsQuery,
    useCreateCalculationTagMutation,
} = calculationTagsApi;

export default calculationTagsApi;
