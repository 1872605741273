import React, { FC } from 'react';
import { SelectField, SelectFieldProps } from "./SelectField";
import { useGetMagento2AttributesQuery } from "../../redux/api/magento2";

export type ColorSelectFieldProps = Omit<SelectFieldProps, "options">;

const ColorSelect: FC<ColorSelectFieldProps> = (props) => {
    const { data: magento2Attributes } = useGetMagento2AttributesQuery();
    let colorAttributeOptions: string | any[] | undefined = [];

    if (magento2Attributes) {
        const colorAttribute = magento2Attributes['hydra:member'].find(attr => attr.attribute_code === 'color');

        if (colorAttribute && colorAttribute.options.length > 0) {
            colorAttributeOptions = colorAttribute.options.map(option => ({
                label: option.label,
                value: option.value
            }));
        }
    }

    if (colorAttributeOptions.length === 0) {
        return null;
    }

    return (
        <SelectField
            {...props}
            options={colorAttributeOptions}
        />
    );
};

export default ColorSelect;
