import React, {FC, SyntheticEvent, useEffect} from "react";
import {Autocomplete, AutocompleteProps, Checkbox, TextField} from "@mui/material";
import {useFormik} from "formik";
import ArrayHelper from "../../helpers/ArrayHelper";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export type AutocompleteOption = {
    value: string,
    label: string,
}

type AutocompleteMultipleFieldProps = Omit<AutocompleteProps<AutocompleteOption, true, false, true> & {
    formik: ReturnType<typeof useFormik> | any, // Typed as OR any because of missing typing
    name: string,
    label: string,
    options: Array<AutocompleteOption>,
    onAdd?: (option: AutocompleteOption) => void,
    onRemove?: (option: AutocompleteOption) => void,
}, 'renderInput'>

export const AutocompleteMultipleField: FC<AutocompleteMultipleFieldProps> = ({formik, name, label, options, onAdd, onRemove, ...restProps}) => {
    const [initialSelection, setInitialSelection] = React.useState<Array<string>>([]);
    const currentValues = ArrayHelper.get(formik.values, name, []);

    useEffect(() => {
        if (0 < options.length) {
            setInitialSelection(
                options
                    .filter((option: AutocompleteOption) => currentValues.includes(option.value))
                    .map((option: AutocompleteOption) => option.value)
            );
        }
    }, [options, currentValues]);

    if (!options || 0 === options.length) {
        return null;
    }

    return (
        <Autocomplete
            multiple
            disableCloseOnSelect
            value={initialSelection}
            options={options.map((option: AutocompleteOption) => option.value)}
            getOptionLabel={option => options.find((o: AutocompleteOption) => o.value === option)?.label || ''}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {options.find((o: AutocompleteOption) => o.value === option)?.label || ''}
                </li>
            )}
            onChange={(event: SyntheticEvent, value: (string)[]) => {
                if (value) {
                    // Call the onAdd or onRemove callback with the added or removed option as argument when the callbacks are not undefined
                    if (typeof onAdd === 'function') {
                        const addedOptions = value
                            .filter((option) => !initialSelection.includes(option))
                            .map(selected => options.find((o: AutocompleteOption | undefined) => o && o.value === selected));
                        addedOptions.forEach((option) => {option && onAdd(option)});
                    }
                    if (typeof onRemove === 'function') {
                        const removedOptions = initialSelection
                            .filter((option: string) => !value.includes(option))
                            .map(selected => options.find((o: AutocompleteOption | undefined) => o && o.value === selected));
                        removedOptions.forEach((option) => {option && onRemove(option)});
                    }
                }

                formik.setFieldValue(name, value);
                // setInitialSelection(value);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    label={label}
                    placeholder={label}
                />
            )}
        />
    );
}
