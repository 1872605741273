import React, {FC} from "react";
import {Alert, AlertColor, Box, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {AppLink} from "../../components/ui/AppLink";
import {LoadingButton} from "../../components/form/LoadingButton";
import useQuery from "../../hooks/useQuery";
import {TextField} from "../../components/form/TextField";
import {PublicLayout} from "../../components/layout/PublicLayout";
import {useLoginMutation} from "../../redux/api/auth";
import {FormikHelpers, useFormik} from "formik";
import ApiErrorHelper from "../../helpers/ApiErrorHelper";
import {useRequestPasswordResetMutation} from "../../redux/api/user";

export const ForgotPasswordPage: FC<{}> = () => {
    const navigate = useNavigate();
    const [requestPasswordReset, {isLoading}] = useRequestPasswordResetMutation();
    const [succesMessage, setSuccesMessage] = React.useState<string>('');
    const query = useQuery();

    const submit = async (values: any, formikHelpers: FormikHelpers<any>) => {
        try {
            setSuccesMessage('');
            await requestPasswordReset(values).unwrap();
            setSuccesMessage('Indien het e-mailadres bekend is, ontvang je een e-mail met instructies om je wachtwoord te resetten.');
        } catch (err) {
            ApiErrorHelper.processErrors(err, formikHelpers);
            formikHelpers.setSubmitting(false);
        }
    };

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        onSubmit: submit,
    });

    if (query.has('email') && !formik.values.email) {
        formik.setFieldValue('email', query.get('email') as string);
    }

    return (
        <PublicLayout>
            <Typography component="h1" variant="h5">
                Wachtwoord vergeten
            </Typography>
            <Typography component="p">
                Vul je e-mailadres in en we sturen je een e-mail met instructies om je wachtwoord te resetten.
            </Typography>

            {succesMessage && <Alert severity="success">{succesMessage}</Alert>}
            <form onSubmit={formik.handleSubmit}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    variant="outlined"
                    label={'E-mailadres'}
                    name="email"
                    formik={formik}
                    autoFocus
                />
                <LoadingButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    color={"primary"}
                    loading={isLoading}
                >
                    Verzoek wachtwoord reset
                </LoadingButton>
            </form>
        </PublicLayout>
    );
}
