import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import {persistor, store} from "./redux/store";
import {CssBaseline, ThemeProvider} from "@mui/material";
import {theme} from "./config/theme";
import {Router} from "./pages/Router";
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
      <ThemeProvider theme={theme}>
          <CssBaseline enableColorScheme />
          <Provider store={store}>
              <PersistGate persistor={persistor} loading={null}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                      <Router />
                  </LocalizationProvider>
              </PersistGate>
          </Provider>
      </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
