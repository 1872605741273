import * as React from 'react';
import {FC} from 'react';
import {GridOverlay} from '@mui/x-data-grid';
import {Box, useTheme} from "@mui/material";

export const DataGridNoRowsOverlay: FC<{}> = () => {
    const theme = useTheme();

    return (
        <GridOverlay>
            <Box sx={{marginTop: theme.spacing(8)}}>Er zijn geen resultaten gevonden.</Box>
        </GridOverlay>
    );
}
