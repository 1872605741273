import React, {FC, useRef, useState} from "react";
import {Grid, Typography} from "@mui/material";
import {PrivateLayout} from "../../components/layout/PrivateLayout";
import DynamicDataGrid, {DynamicDataGridHandle} from "../../components/datagrid/DynamicDataGrid";
import {GridColDef} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {RowActions} from "../../components/datagrid/RowActions";
import {AddButton} from "../../components/form/AddButton";
import {UserResponse} from "../../types/api/responses/user/UserResponse";
import {ConfirmDialog} from "../../components/dialogs/ConfirmDialog";
import {
    useBulkActivateOptionGroupMutation,
    useBulkDeactivateOptionGroupMutation,
    useDeleteOptionGroupMutation,
    useGetOptionGroupsQuery,
    useLazyGetOptionDuplicationNameQuery,
    useLazyGetOptionDuplicationSkuQuery
} from "../../redux/api/option-groups";
import {OptionGroupForm} from "./OptionGroups/OptionGroupForm";
import {useGetSuppliersQuery} from "../../redux/api/suppliers";
import {LoadableContainer} from "../../components/ui/LoadableContainer";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import {BooleanRenderer} from '../../components/datagrid/renderers/BooleanRenderer';
import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';
import NotificationHelper from '../../helpers/NotificationHelper';
import {RequestFilter} from "../../types/api/requests/DynamicListRequest";
import {OptionGroupsFilter} from "./OptionGroups/OptionGroupsFilter";

export const OptionGroupsPage: FC<{}> = () => {
    const [deleteSupplier] = useDeleteOptionGroupMutation();
    const [getOptionGroupDuplicationName] = useLazyGetOptionDuplicationNameQuery();
    const [getOptionGroupDuplicationSku] = useLazyGetOptionDuplicationSkuQuery();
    const [formOpen, setFormOpen] = useState(false);
    const [selectedForEditing, setSelectedForEditing] = useState<UserResponse|null>(null);
    const [selectedForDeleting, setSelectedForDeleting] = useState<UserResponse|null>(null);
    const [confirmData, setConfirmData] = useState<{title: string, message: string, onConfirm: () => void} | null>(null);
    const gridRef = useRef<DynamicDataGridHandle|null>(null);
    const {data: suppliers, isLoading: isSuppliersLoading} = useGetSuppliersQuery({});
    const [bulkActivate] = useBulkActivateOptionGroupMutation();
    const [bulkDeactivate] = useBulkDeactivateOptionGroupMutation();
    const [filters, setFilters] = useState<RequestFilter>([{field: 'shared', value: ''}]);

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', flex: 1 },
        {
            field: 'sku',
            headerName: 'SKU',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'name',
            headerName: 'Naam',
            minWidth: 400,
            flex: 1,
        },
        {
            field: 'supplier.name',
            headerName: 'Leverancier',
            minWidth: 400,
            flex: 1,
            renderCell: (params) => {
                if (suppliers) {
                    const supplier = suppliers['hydra:member']?.find(s => s['@id'] === params.row.supplier);

                    return supplier?.name;
                }

                return params.row.supplier;
            }
        },
        {
            field: 'active',
            headerName: 'Actief',
            flex: 1,
            renderCell: (params) => {
                return <BooleanRenderer value={params.row.active} />;
            }
        },
        {
            field: 'actions',
            headerName: 'Acties',
            flex: 1,
            renderCell: (params) => {
                const actions = [
                    {
                        icon: <EditIcon/>,
                        label: 'Wijzigen',
                        onClick: () => {
                            setSelectedForEditing(params.row);
                            setFormOpen(true);
                        }
                    },
                    {
                        icon: <ContentCopyOutlinedIcon/>,
                        label: 'Dupliceren',
                        onClick: async () => {
                            const clone = JSON.parse(JSON.stringify(params.row));

                            clone.id = null;
                            clone.name = await getOptionGroupDuplicationName(clone.name).unwrap();
                            clone.sku = await getOptionGroupDuplicationSku(clone.sku).unwrap();
                            clone.active = false;

                            clone.options = clone.options.map((option: any) => {
                                option.id = null;
                                option['@id'] = null;

                                option.priceTiers = option.priceTiers?.map((priceTier: any) => {
                                    priceTier.id = null;
                                    priceTier['@id'] = null;

                                    return priceTier;
                                });

                                return option;
                            });

                            setSelectedForEditing(clone);
                            setFormOpen(true);
                        }
                    },
                ];

                if (!params.row.active && params.row.activatable) {
                    actions.push({
                        icon: <ToggleOnOutlinedIcon/>,
                        label: 'Activeren',
                        onClick: () => setConfirmData({
                            title: 'Optie groep activeren',
                            message: `Weet je zeker dat je optie-groep "${params.row.name ?? params.row.id}" wilt activeren?`,
                            onConfirm: async () => {
                                await handleBulkActivate([params.row.id]);
                            }
                        })
                    });
                }

                if (params.row.active && params.row.deactivatable) {
                    actions.push({
                        icon: <ToggleOffOutlinedIcon />,
                        label: 'Deactiveren',
                        onClick: () => setConfirmData({
                            title: 'Optie groep deactiveren',
                            message: `Weet je zeker dat je optie-groep "${params.row.name ?? params.row.id}" wilt deactiveren?`,
                            onConfirm: async () => {
                                await handleBulkDeactivate([params.row.id]);
                            }
                        })
                    })
                }

                actions.push({
                    icon: <DeleteForeverIcon/>,
                    label: 'Verwijderen',
                    onClick: async () => {
                        setSelectedForDeleting(params.row);
                    }
                });

                return <RowActions actions={actions}/>;
            },
        },
    ];

    const handleCreateClick = () => {
        setSelectedForEditing(null);
        setFormOpen(true);
    }

    const handleCloseFormDialog = () => {
        setFormOpen(false);
        gridRef.current?.reload();
    }

    const handleDeleteConfirm = async () => {
        if (selectedForDeleting) {
            await deleteSupplier(selectedForDeleting.id).unwrap();
            setSelectedForDeleting(null);
            gridRef.current?.reload();
        }
    }
    const handleDeleteDecline = () => {
        setSelectedForDeleting(null);
    }

    const handleBulkActivate = async (ids: Array<number>) => {
        try {
            await bulkActivate(ids).unwrap();
            gridRef.current?.reload();
            setConfirmData(null);
            NotificationHelper.showSuccess('De geselecteerde optie-groepen zijn geactiveerd.');
        } catch (e) {
            NotificationHelper.showError('Er is een fout opgetreden bij het activeren van de optie-groepen. Probeer het later nog eens.');
        }
    }

    const handleBulkDeactivate = async (ids: Array<number>) => {
        try {
            await bulkDeactivate(ids).unwrap();
            gridRef.current?.reload();
            setConfirmData(null);
            NotificationHelper.showSuccess('De geselecteerde optie-groepen zijn gedeactiveerd.');
        } catch (e) {
            NotificationHelper.showError('Er is een fout opgetreden bij het deactiveren van de optie-groepen. Probeer het later nog eens.');
        }
    }

    return (
        <PrivateLayout>
            <LoadableContainer isLoading={isSuppliersLoading}>
                <Grid container>
                    <Grid item xs={12} sm={6} mb={3}>
                        <Typography component="h1" variant="h5">
                            Optiegroepen
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} textAlign={'right'}>
                        <AddButton title="Optiegroep toevoegen" onClick={handleCreateClick} />
                    </Grid>

                    <OptionGroupsFilter filters={filters} onFiltersChanged={setFilters} />

                    <DynamicDataGrid
                        ref={gridRef}
                        filter={filters}
                        columns={columns}
                        queryHook={useGetOptionGroupsQuery}
                        autoHeight={true}
                        checkboxSelection={false}
                        disableRowSelectionOnClick={true}
                    />
                </Grid>

                <OptionGroupForm
                    action={selectedForEditing?.id ? 'edit' : 'create'}
                    open={formOpen}
                    close={handleCloseFormDialog}
                    initialValues={selectedForEditing}
                />

                {selectedForDeleting && (
                    <ConfirmDialog
                        title={'Optiegroep verwijderen'}
                        message={`Weet je zeker dat je optiegroep "${selectedForDeleting.name}" wilt verwijderen?`}
                        onYes={handleDeleteConfirm}
                        onNo={handleDeleteDecline}
                    />
                )}

                {confirmData && (
                    <ConfirmDialog
                        title={confirmData.title}
                        message={confirmData.message}
                        onYes={confirmData.onConfirm}
                        onNo={() => setConfirmData(null)}
                    />
                )}
            </LoadableContainer>
        </PrivateLayout>
    );
}
