import React, {ChangeEvent, FC} from "react";
import {ButtonProps} from "@mui/material/Button/Button";
import {useUploadImageMutation} from "../../redux/api/images";
import {UploadFileOutlined} from "@mui/icons-material";
import {Button, LinearProgress} from "@mui/material";
import {ImageResponse} from "../../types/api/responses/image/ImageResponse";

type ImageUploadFieldProps = ButtonProps & {
    label?: string,
    onUploaded?: (images: ImageResponse[], files: File[]) => void,
}

export const MultipleImageUploadField: FC<ImageUploadFieldProps> = ({label, onUploaded, sx}) => {
    const [uploadImage, {isLoading: isUploading}] = useUploadImageMutation();

    const handleFilesChange = async (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || e.target.files.length === 0) {
            return;
        }

        const filesArray = Array.from(e.target.files);
        const uploadPromises = filesArray.map(file => {
            let formData = new FormData();
            formData.append('file', file);
            return uploadImage(formData).unwrap();
        });

        try {
            const uploadedImages = await Promise.all(uploadPromises);
            onUploaded && onUploaded(uploadedImages, filesArray);
        } catch (error) {
            console.error('Fout bij het uploaden van afbeeldingen:', error);
        }
    };

    return (
        <Button
            component="label"
            variant="outlined"
            startIcon={isUploading ? <LinearProgress
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                }}
            /> : <UploadFileOutlined />}
            sx={{
                marginRight: "1rem",
                ...sx
            }}
            title="Per afbeelding wordt er een nieuwe rij aangemaakt"
        >
            {label ? label : 'Afbeelding(en) uploaden'}
            <input type="file" accept=".png,.jpg,.jpeg,image/png,image/jpg,image/jpeg" hidden multiple onChange={handleFilesChange} />
        </Button>
    );
}
