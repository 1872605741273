import React, { FC, useState } from "react";
import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps, Button, Modal, Box, TextareaAutosize, InputAdornment } from "@mui/material";
import { useFormik } from "formik";
import ArrayHelper from "../../helpers/ArrayHelper";
import {WysiwygField} from "./WysiwygField";

export type TextFieldProps = MuiTextFieldProps & {
    formik: ReturnType<typeof useFormik> | any, // Typed as OR any because of missing typing
    name: string,
    valueParser?: (value: string) => any,
    onChange?: (event: React.ChangeEvent<any>) => void,
};

export const TextFieldWithWysiwygEditor: FC<TextFieldProps> = ({ formik, name, label, onChange, valueParser, ...restProps }) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [editorValue, setEditorValue] = useState("");

    const value = ArrayHelper.get(formik.values, name);

    const defaultOnChange = (event: React.ChangeEvent<any>) => {
        if (valueParser) {
            formik.setFieldValue(event.target.name, valueParser(event.target.value));
        } else {
            formik.handleChange(event);
        }

        onChange && onChange(event);
    };

    const handleOpenModal = () => {
        setEditorValue(value); // Initialize editor with current field value
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    return (
        <>
            <MuiTextField
                id={name}
                name={name}
                label={label || name}
                value={value}
                onChange={defaultOnChange}
                error={formik.touched[name] && Boolean(formik.errors[name])}
                helperText={formik.touched[name] && formik.errors[name] as string}
                variant="standard"
                size="small"
                margin="normal"
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Button onClick={handleOpenModal} size="small">Aanpassen</Button>
                        </InputAdornment>
                    ),
                }}
                {...restProps}
            />
            <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableEnforceFocus
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <WysiwygField formik={formik} name={name}/>
                    <Button sx={{marginTop: "20px"}} variant="contained" onClick={handleCloseModal}>Opslaan</Button>
                </Box>
            </Modal>
        </>
    );
};
