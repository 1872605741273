import api from "./api";
import {AttributesResponse} from "../../types/api/responses/magento2/AttributesResponse";
import {CmsBlocksResponse} from "../../types/api/responses/magento2/CmsBlocksResponse";

const magento2Api = api.injectEndpoints({
    endpoints: build => ({
        getMagento2Attributes: build.query<AttributesResponse, void>({
            query: (request) => 'api/magento2/attributes',
        }),

        getMagento2CmsBlocks: build.query<CmsBlocksResponse, void>({
            query: (request) => 'api/magento2/cms-blocks',
        }),
    }),
});

export const {
    useGetMagento2AttributesQuery,
    useGetMagento2CmsBlocksQuery,
} = magento2Api;

export default magento2Api;
