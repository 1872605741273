import api from "./api";
import TokenResponse from "../../types/api/responses/auth/TokenResponse";
import TokenRequest from "../../types/api/requests/auth/TokenRequest";
import ValidateTwoFactorCodeRequest from "../../types/api/requests/auth/ValidateTwoFactorCodeRequest";
import SetupTwoFactorAuthenticationResponse from "../../types/api/responses/auth/SetupTwoFactorAuthenticationResponse";
import SetupTwoFactorAuthenticationRequest from "../../types/api/requests/auth/SetupTwoFactorAuthenticationRequest";
import ResetTwoFactorAuthenticationRequest from "../../types/api/requests/auth/ResetTwoFactorAuthenticationRequest";

const authApi = api.injectEndpoints({
    endpoints: build => ({
        login: build.mutation<TokenResponse, TokenRequest>({
            query: (credentials) => ({
                url: '/token',
                method: 'POST',
                body: credentials,
            }),
        }),
        validateTwoFactorCode: build.mutation<TokenResponse, ValidateTwoFactorCodeRequest>({
            query: ({ code }) => ({
                url: '/auth/validate-2fa',
                method: 'POST',
                body: { code },
            }),
        }),
        setupTwoFactorAuthentication: build.mutation<SetupTwoFactorAuthenticationResponse, SetupTwoFactorAuthenticationRequest>({
            query: ({ regenerate }) => ({
                url: '/auth/setup-2fa',
                method: 'POST',
                body: { regenerate },
            }),
        }),
        resetTwoFactorAuthentication: build.mutation<void, ResetTwoFactorAuthenticationRequest>({
            query: ({ email }) => ({
                url: '/auth/reset-2fa',
                method: 'POST',
                body: { email },
            }),
        }),
    }),
});

export const { useLoginMutation } = authApi;
export const { useValidateTwoFactorCodeMutation } = authApi;
export const { useSetupTwoFactorAuthenticationMutation } = authApi;
export const { useResetTwoFactorAuthenticationMutation } = authApi;

export default authApi;
