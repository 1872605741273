import api from "./api";
import {ImageResponse} from "../../types/api/responses/image/ImageResponse";
import {PutImageRequest} from "../../types/api/requests/image/PutImageRequest";

const imagesApi = api.injectEndpoints({
    endpoints: build => ({
        getImage: build.query<ImageResponse, number>({
            query: (id) => `/api/images/${id}`,
        }),

        uploadImage: build.mutation<ImageResponse, FormData>({
            query: (image) => ({
                url: '/api/images',
                method: 'POST',
                body: image,
            })
        }),

        updateImage: build.mutation<ImageResponse, {id: number, body: PutImageRequest}>({
            query: (image) => ({
                url: `/api/images/${image.id}`,
                method: 'PUT',
                body: {...image.body},
            })
        }),

        deleteImage: build.mutation<void, number>({
            query: (id) => ({
                url: `/api/images/${id}`,
                method: 'DELETE',
            })
        }),
    }),
});

export const {
    useUploadImageMutation,
    useUpdateImageMutation,
    useDeleteImageMutation,
} = imagesApi;

export default imagesApi;
