import api from "./api";
import DynamicListRequest from "../../types/api/requests/DynamicListRequest";
import UrlHelper from "../../helpers/UrlHelper";
import {DeliveriesResponse} from "../../types/api/responses/exact-online-deliveries/DeliveriesResponse";

const exactOnlineDeliveriesApi = api.injectEndpoints({
    endpoints: build => ({
        getDeliveries: build.query<DeliveriesResponse, DynamicListRequest>({
            query: (request) => UrlHelper.buildUrl('api/exact_deliveries', request),
        }),
    }),
});

export const {
    useGetDeliveriesQuery,
} = exactOnlineDeliveriesApi;

export default exactOnlineDeliveriesApi;
