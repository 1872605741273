import React, {FC, useRef} from "react";
import {Grid, Typography} from "@mui/material";
import {PrivateLayout} from "../../components/layout/PrivateLayout";
import DynamicDataGrid, {DynamicDataGridHandle} from "../../components/datagrid/DynamicDataGrid";
import {GridColDef} from "@mui/x-data-grid";
import {useGetDeliveriesQuery} from "../../redux/api/exact-online-deliveries";
import {DeliveryCustomerRenderer} from "../../components/datagrid/renderers/DeliveryCustomerRenderer";
import {DeliveryTrackingLinksRenderer} from "../../components/datagrid/renderers/DeliveryTrackingLinksRenderer";
import {DateTimeRenderer} from "../../components/datagrid/renderers/DateTimeRenderer";

export const DeliveriesPage: FC<{}> = () => {
    const gridRef = useRef<DynamicDataGridHandle|null>(null);

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'Bestelling', flex: 1, renderCell: params => params.row.deliveryLines[0].orderLine.order?.orderNumber },
        {
            field: 'createdAt',
            headerName: 'Datum',
            flex: 1,
            renderCell: (params) => (<DateTimeRenderer date={new Date(params.row.createdAt)}/>)
        },
        {
            field: 'exactCustomer.name',
            headerName: 'Klant',
            flex: 1,
            renderCell: (params) => (<DeliveryCustomerRenderer customer={params.row.exactCustomer}/>)
        },
        {
            field: 'trackingLinks',
            headerName: 'Tracking links',
            flex: 1,
            renderCell: (params) => (<DeliveryTrackingLinksRenderer links={params.row.trackingLinks}/>)
        },
        {
            field: 'isTrackingMailSent',
            headerName: 'Mail verzonden',
            flex: 1,
            renderCell: (params) => params.row.trackingMailSent ? 'Ja' : 'Nee'
        },
    ];

    return (
        <PrivateLayout>
            <Grid container>
                <Grid item xs={12} sm={6} mb={3}>
                    <Typography component="h1" variant="h5">
                        Leveringen
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} textAlign={'right'}>
                    {/* Buttons */}
                </Grid>

                <DynamicDataGrid
                    ref={gridRef}
                    columns={columns}
                    queryHook={useGetDeliveriesQuery}
                    autoHeight={true}
                    checkboxSelection={false}
                    disableRowSelectionOnClick={true}
                />
            </Grid>
        </PrivateLayout>
    );
}
