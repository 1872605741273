import React, {FC} from "react";
import {Alert, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {LoadingButton} from "../../components/form/LoadingButton";
import useQuery from "../../hooks/useQuery";
import {TextField} from "../../components/form/TextField";
import {PublicLayout} from "../../components/layout/PublicLayout";
import {FormikHelpers, useFormik} from "formik";
import ApiErrorHelper from "../../helpers/ApiErrorHelper";
import {useResetPasswordMutation} from "../../redux/api/user";
import {useValidateActionTokenMutation} from "../../redux/api/action-token";
import {useLoginMutation} from "../../redux/api/auth";

export const ResetPasswordPage: FC<{}> = () => {
    const navigate = useNavigate();
    const [login, {isLoading: isLoadingLogin}] = useLoginMutation();
    const [resetPassword, {isLoading: isLoadingResetPassword}] = useResetPasswordMutation();
    const [validateActionToken, {isLoading: isLoadingValidateActionToken}] = useValidateActionTokenMutation();
    const query = useQuery();
    const [invalidTokenMessage, setInvalidTokenMessage] = React.useState<string>('');
    const [twoPasswordsMatch, setTwoPasswordsMatch] = React.useState<boolean>(false);

    React.useEffect(() => {
        const token = query.get('token') || '';
        if (!token) {
            setInvalidTokenMessage('Deze link is niet geldig.');
            return;
        }

        const validate = async () => {
            const response = await validateActionToken({token}).unwrap();
            formik.initialValues.email = response.email;
        }

        validate()
            .catch(() => (setInvalidTokenMessage('Deze link is niet geldig.')));
    }, [query.get('token')]);

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const firstPassword = event.target.name === 'password' ? event.target.value : formik.values.password;
        const secondPassword = event.target.name === 'confirmPassword' ? event.target.value : formik.values.confirmPassword;

        setTwoPasswordsMatch(firstPassword === secondPassword);
    }

    const submit = async (values: any, formikHelpers: FormikHelpers<any>) => {
        try {
            await resetPassword(values).unwrap();
            await login({email: values.email, password: values.password}).unwrap();
            navigate('/calculations?m=success|Je wachtwoord is gereset en je bent ingelogd.');
        } catch (err) {
            ApiErrorHelper.processErrors(err, formikHelpers);
            formikHelpers.setSubmitting(false);
        }
    };


    const formik = useFormik({
        initialValues: {
            token: query.get('token') || '',
            email: '',
            password: '',
            confirmPassword: '',
        },
        onSubmit: submit,
    });

    return (
        <PublicLayout>
            {invalidTokenMessage &&
                <>
                    <Alert severity="error">{invalidTokenMessage}</Alert>
                    <Typography component="p">
                        <LoadingButton
                            onClick={() => navigate('/2fa/setup')}
                            fullWidth
                            variant="contained"
                            color={"primary"}
                        >
                            Terug naar login
                        </LoadingButton>
                    </Typography>
                </>
            }
            {!invalidTokenMessage &&
                <>
                    <Typography component="h1" variant="h5">
                        Reset je wachtwoord
                    </Typography>
                    <Typography component="p">
                        Vul je nieuwe wachtwoord in.
                    </Typography>

                    <form onSubmit={formik.handleSubmit}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            variant="outlined"
                            name="password"
                            label={'Wachtwoord'}
                            formik={formik}
                            type="password"
                            onChange={handlePasswordChange}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            variant="outlined"
                            name="confirmPassword"
                            label={'Bevestig wachtwoord'}
                            formik={formik}
                            type="password"
                            onChange={handlePasswordChange}
                            error={!twoPasswordsMatch && formik.values.confirmPassword !== ''}
                            helperText={(!twoPasswordsMatch && formik.values.confirmPassword !== '') ? 'Wachtwoorden komen niet overeen' : ''}
                        />
                        <LoadingButton
                            type="submit"
                            fullWidth
                            variant="contained"
                            color={"primary"}
                            disabled={!twoPasswordsMatch}
                            loading={isLoadingResetPassword || isLoadingValidateActionToken}
                        >
                            Reset wachtwoord
                        </LoadingButton>
                    </form>
                </>
            }
        </PublicLayout>
    );
}
