import api from "./api";
import KeyValue from "../../types/KeyValue";
import {GetEmailsResponse} from "../../types/api/responses/settings/GetEmailsResponse";

const settingsApi = api.injectEndpoints({
    endpoints: build => ({
        getSettings: build.query<any, void>({
            query: () => ({
                url: 'api/settings',
            }),
            providesTags: ['settings'],
        }),

        saveSettings: build.mutation<boolean, KeyValue<string>>({
            query: (body) => ({
                url: 'api/settings',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['settings'],
        }),

        getEmails: build.query<GetEmailsResponse, void>({
            query: () => ({
                url: 'api/settings/emails',
            }),
        }),
    }),
});

export const {
    useGetSettingsQuery,
    useSaveSettingsMutation,
    useGetEmailsQuery,
} = settingsApi;

export default settingsApi;
