import React, {FC} from "react";
import {DataGrid as MuiDataGrid, DataGridProps as MuiDataGridProps, GridFooter} from '@mui/x-data-grid';
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {setDataGridSelection} from "../../redux/slices/datagrid";
import {DataGridNoRowsOverlay} from "./DataGridNoRowsOverlay";
import {DataGridToolbar, DataGridToolbarAction} from "./DataGridToolbar";
import {styled} from "@mui/material";

export type DataGridProps = MuiDataGridProps & {
    actions?: Array<DataGridToolbarAction>,
    onSelectionChanged?: (selection: Array<string | number>) => void,
};

export const DataGrid: FC<DataGridProps> = ({actions, onSelectionChanged, ...props}) => {
    const dispatch = useAppDispatch();

    return (
        <StyledDataGrid
            initialState={{
                pagination: {
                    paginationModel: {
                        pageSize: 25,
                        page: 0
                    }
                }
            }}
            pageSizeOptions={[25, 50, 75, 100]}
            slots={{
                noRowsOverlay: DataGridNoRowsOverlay,
                toolbar: () => <DataGridToolbar actions={actions}/>,
                footer: () =>
                        <div style={{display: 'flex'}}>
                            <div style={{minWidth: 'max-content', borderTop: '1px solid #E0E0E0'}}>
                                <DataGridToolbar actions={actions} style={{marginTop: '5px'}}/>
                            </div>
                            <div style={{width: '100%' }}>
                                <GridFooter />
                            </div>
                        </div>,
            }}
            // disableColumnFilter
            onRowSelectionModelChange={selection => {
                dispatch(setDataGridSelection(selection));
                onSelectionChanged && onSelectionChanged(selection);
            }}
            {...props}
        />
    );
}

const StyledDataGrid = styled(MuiDataGrid)(() => ({
    root: {
        '& .MuiDataGrid-renderingZone': {
            maxHeight: 'none !important',
        },
        '& .MuiDataGrid-cell': {
            display: 'flex',
            lineHeight: 'unset !important',
            maxHeight: 'none !important',
            whiteSpace: 'normal',
            alignItems: 'center',
            justifyContent: 'flex-start',
        },
        '& .MuiDataGrid-row': {
            maxHeight: 'none !important',
        },
    },
}));
