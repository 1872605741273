// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, {FC, useEffect} from 'react';
import {useFormik} from "formik";
import ArrayHelper from "../../helpers/ArrayHelper";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import {Box, GlobalStyles} from "@mui/material";

export type WysiwygFieldProps = {
    formik: ReturnType<typeof useFormik>,
    name: string,
}

const inputGlobalStyles = (
    <GlobalStyles
        styles={{
            ".ck.ck-balloon-panel": {
                zIndex: "1400 !important", // Put a higher value that your MUI Dialog (generaly 1300)
            },
        }}
    />
);

export const WysiwygField: FC<WysiwygFieldProps> = ({formik, name}) => {
    const value = ArrayHelper.get(formik.values, name);

    return (
        <Box>
            {inputGlobalStyles}
            <CKEditor
                editor={Editor}
                data={value || ''}
                onChange={(event, editor) => formik.setFieldValue(name, editor.getData())}
            />
        </Box>

    )
}
