import {createTheme} from "@mui/material";

export const theme = createTheme({
    palette: {
        primary: {
            main: '#514aa7',
        },
        secondary: {
            main: '#f2f2f2',
        },
    },
    components: {
        MuiAppBar: {
            defaultProps: {
                elevation: 0,
            },
        },
        MuiAccordion: {
            defaultProps: {
                elevation: 0,
            },
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: '#f2f2f2',
                    },
                    '&.Mui-selected:hover': {
                        backgroundColor: '#f2f2f2',
                    },
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                asterisk: {
                    color: 'red',
                }
            }
        },
        MuiMenu: {
            defaultProps: {
                elevation: 2,
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: '#f2f2f2',
                    },
                    '&.Mui-selected:hover': {
                        backgroundColor: '#f2f2f2',
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '&.MuiInputBase-input': {
                        padding: '12px',
                    },
                },
            },
        },
    },
});
