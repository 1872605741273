import React, {FC} from "react";
import {Card, CardProps, styled, Typography} from "@mui/material";


type FieldSetProps = CardProps & {
    title: string,
    children: React.ReactNode,
}

export const FieldSet: FC<FieldSetProps> = ({title, children, ...restProps}) => {
    return (
        <FieldSetCard variant="outlined" {...restProps}>
            <Typography variant="h6" sx={{marginBottom: 1}}>
                {title}
            </Typography>

            {children}
        </FieldSetCard>
    );
}

const FieldSetCard = styled(Card)(({ theme }) => ({
    flexBasis: '100%',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
}));
