import api from "./api";
import DynamicListRequest from "../../types/api/requests/DynamicListRequest";
import UrlHelper from "../../helpers/UrlHelper";
import {MarginGroupsResponse} from "../../types/api/responses/margin-group/MarginGroupsResponse";
import {MarginGroupResponse} from "../../types/api/responses/margin-group/MarginGroupResponse";
import {MarginGroupRequest} from "../../types/api/requests/margin-group/MarginGroupRequest";

const marginGroupApi = api.injectEndpoints({
    endpoints: build => ({
        getMarginGroups: build.query<MarginGroupsResponse, DynamicListRequest>({
            query: (request) => UrlHelper.buildUrl('api/margin_groups', request),
            providesTags: ['margin-groups'],
        }),

        createMarginGroup: build.mutation<MarginGroupResponse, MarginGroupRequest>({
            query: (body) => ({
                url: '/api/margin_groups',
                method: 'POST',
                body: body,
            }),
            invalidatesTags: ['margin-groups', 'calculation-questions', 'calculations']
        }),

        updateMarginGroup: build.mutation<MarginGroupResponse, MarginGroupRequest>({
            query: (body) => ({
                url: `/api/margin_groups/${body.id}`,
                method: 'PUT',
                body: body,
            }),
            invalidatesTags: ['margin-groups', 'calculation-questions', 'calculations']
        }),

        deleteMarginGroup: build.mutation<boolean, number>({
            query: (id) => ({
                url: `/api/margin_groups/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['margin-groups']
        }),
    }),
});

export const {
    useGetMarginGroupsQuery,
    useCreateMarginGroupMutation,
    useUpdateMarginGroupMutation,
    useDeleteMarginGroupMutation,
} = marginGroupApi;

export default marginGroupApi;
