import {Link, LinkProps} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import {FC, PropsWithChildren} from "react";

type AppLinkProps = PropsWithChildren<LinkProps & {
    to: string | object;
}>;

export const AppLink: FC<AppLinkProps> = (props) => {
    return (
        <Link component={RouterLink} {...props}>
            {props.children}
        </Link>
    );
}
