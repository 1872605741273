import React, {FC, useRef, useState} from "react";
import {Grid, Typography} from "@mui/material";
import {PrivateLayout} from "../../components/layout/PrivateLayout";
import DynamicDataGrid, {DynamicDataGridHandle} from "../../components/datagrid/DynamicDataGrid";
import {GridColDef} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {RowActions} from "../../components/datagrid/RowActions";
import {AddButton} from "../../components/form/AddButton";
import {UserResponse} from "../../types/api/responses/user/UserResponse";
import {ConfirmDialog} from "../../components/dialogs/ConfirmDialog";
import {useDeleteMarginGroupMutation, useGetMarginGroupsQuery} from "../../redux/api/margin-groups";
import {MarginGroupForm} from "./MarginGroups/MarginGroupForm";

export const MarginGroupsPage: FC<{}> = () => {
    const [deleteSupplier] = useDeleteMarginGroupMutation();
    const [formOpen, setFormOpen] = useState(false);
    const [selectedForEditing, setSelectedForEditing] = useState<UserResponse|null>(null);
    const [selectedForDeleting, setSelectedForDeleting] = useState<UserResponse|null>(null);
    const gridRef = useRef<DynamicDataGridHandle|null>(null);

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', flex: 1 },
        {
            field: 'name',
            headerName: 'Naam',
            flex: 1,
        },
        {
            field: 'margin',
            headerName: 'Marge %',
            flex: 1,
            renderCell: (params) => {
                return `${params.row.margin.toFixed(2)}%`;
            }
        },
        {
            field: 'actions',
            headerName: 'Acties',
            flex: 1,
            renderCell: (params) => {
                const actions = [
                    {
                        icon: <EditIcon/>,
                        label: 'Wijzigen',
                        onClick: () => {
                            setSelectedForEditing(params.row);
                            setFormOpen(true);
                        }
                    },
                    {
                        icon: <DeleteForeverIcon/>,
                        label: 'Verwijderen',
                        onClick: async () => {
                            setSelectedForDeleting(params.row);
                        }
                    },
                ];

                return <RowActions actions={actions}/>;
            },
        },
    ];

    const handleCreateClick = () => {
        setSelectedForEditing(null);
        setFormOpen(true);
    }

    const handleCloseFormDialog = () => {
        setFormOpen(false);
        gridRef.current?.reload();
    }

    const handleDeleteConfirm = async () => {
        if (selectedForDeleting) {
            await deleteSupplier(selectedForDeleting.id).unwrap();
            setSelectedForDeleting(null);
            gridRef.current?.reload();
        }
    }
    const handleDeleteDecline = () => {
        setSelectedForDeleting(null);
    }

    return (
        <PrivateLayout>
            <Grid container>
                <Grid item xs={12} sm={6} mb={3}>
                    <Typography component="h1" variant="h5">
                        Margegroepen
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} textAlign={'right'}>
                    <AddButton title="Margegroep toevoegen" onClick={handleCreateClick} />
                </Grid>

                <DynamicDataGrid
                    ref={gridRef}
                    columns={columns}
                    queryHook={useGetMarginGroupsQuery}
                    autoHeight={true}
                    checkboxSelection={false}
                    disableRowSelectionOnClick={true}
                />
            </Grid>

            <MarginGroupForm
                action={null === selectedForEditing ? 'create' : 'edit'}
                open={formOpen}
                close={handleCloseFormDialog}
                initialValues={selectedForEditing}
            />

            {selectedForDeleting && (
                <ConfirmDialog
                    title={'Margegroep verwijderen'}
                    message={`Weet je zeker dat je margegroep "${selectedForDeleting.name}" wilt verwijderen?`}
                    onYes={handleDeleteConfirm}
                    onNo={handleDeleteDecline}
                />
            )}
        </PrivateLayout>
    );
}
