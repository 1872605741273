import React, {FC} from "react";
import {Checkbox, CheckboxProps, FormControlLabel} from "@mui/material";
import {useFormik} from "formik";
import ArrayHelper from "../../helpers/ArrayHelper";

type CheckboxFieldProps = CheckboxProps & {
    formik: ReturnType<typeof useFormik> | any, // Typed as OR any because of missing typing
    name: string,
    label: string,
}

export const CheckboxField: FC<CheckboxFieldProps> = ({formik, name, label, ...restProps}) => {
    return (
        <FormControlLabel
            control={<Checkbox
                checked={ArrayHelper.get(formik.values, name, false)}
                onChange={(event) => formik.setFieldValue(name, event.target.checked)}
                name={name}
                {...restProps}
            />}
            label={label}
        />
    );
}
