import api from "./api";
import DynamicListRequest from "../../types/api/requests/DynamicListRequest";
import UrlHelper from "../../helpers/UrlHelper";
import {CalculationsResponse} from "../../types/api/responses/calculation/CalculationsResponse";
import {CalculationResponse} from "../../types/api/responses/calculation/CalculationResponse";
import {CalculationQuestionsResponse} from "../../types/api/responses/calculation/CalculationQuestionsResponse";

const calculationsApi = api.injectEndpoints({
    endpoints: build => ({
        getCalculations: build.query<CalculationsResponse, DynamicListRequest>({
            query: (request) => UrlHelper.buildUrl('api/calculations', request),
            providesTags: ['calculations'],
        }),

        getCalculationsCollection: build.query<CalculationsResponse, DynamicListRequest>({
            query: (request) => UrlHelper.buildUrl('api/calculations/listing', request),
            providesTags: ['calculations'],
        }),

        getCalculation: build.query<CalculationResponse, string>({
            query: (url) => ({
                url: url,
                method: 'GET',
            }),
        }),

        getCalculationDuplicationName: build.query<string, string>({
            query: (name) => ({
                url: '/calculations/next_duplicate_name',
                params: { name },
                method: 'GET',
            }),
        }),
        getCalculationDuplicationSku: build.query<string, string>({
            query: (sku) => ({
                url: '/calculations/next_duplicate_sku',
                params: { sku },
                method: 'GET',
            }),
        }),

        createCalculation: build.mutation<CalculationResponse, any>({
            query: (calculation) => ({
                url: '/api/calculations',
                method: 'POST',
                body: JSON.stringify(calculation),
                headers: {'Content-Type': 'application/ld+json'}
            }),
            invalidatesTags: ['calculations']
        }),

        updateCalculation: build.mutation<CalculationResponse, any>({
            query: (calculation) => ({
                url: `/api/calculations/${calculation.id}`,
                method: 'PUT',
                body: JSON.stringify(calculation),
                headers: {'Content-Type': 'application/ld+json'}
            }),
            invalidatesTags: ['calculations']
        }),

        deleteCalculation: build.mutation<boolean, number>({
            query: (id) => ({
                url: `/api/calculations/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['calculations']
        }),

        getQuestionsForCalculation: build.query<CalculationQuestionsResponse, {id: number, filters: DynamicListRequest}>({
            query: (request) => UrlHelper.buildUrl(`api/calculations/${request.id}/questions`, request.filters),
            providesTags: ['calculation-questions'],
        }),

        exportCalculcationOptions: build.query<{url: string}, Array<number>>({
            query: (ids) => {
                let url = 'api/calculations/export?';

                ids.forEach(id => {
                    url += `calculationIds[]=${id}&`;
                });

                return {
                    url,
                }
            },
        }),

        importCalculcationOptions: build.mutation<void, FormData>({
            query: (request) => ({
                url: 'api/calculations/import',
                method: 'POST',
                body: request,
            }),
            invalidatesTags: ['calculations', 'calculation-questions']
        }),

        bulkActivateCalculation: build.mutation<void, Array<number>>({
            query: (request) => ({
                url: 'api/calculations/activate',
                method: 'PUT',
                body: {calculationIds: request},
            }),
            invalidatesTags: ['calculations']
        }),

        bulkDeactivateCalculation: build.mutation<void, Array<number>>({
            query: (request) => ({
                url: 'api/calculations/deactivate',
                method: 'PUT',
                body: {calculationIds: request},
            }),
            invalidatesTags: ['calculations']
        }),
    }),
});

export const {
    useGetCalculationsQuery,
    useGetCalculationsCollectionQuery,
    useGetCalculationQuery,
    useCreateCalculationMutation,
    useUpdateCalculationMutation,
    useDeleteCalculationMutation,
    useGetQuestionsForCalculationQuery,
    useLazyGetCalculationDuplicationNameQuery,
    useLazyGetCalculationDuplicationSkuQuery,
    useLazyExportCalculcationOptionsQuery,
    useImportCalculcationOptionsMutation,
    useBulkActivateCalculationMutation,
    useBulkDeactivateCalculationMutation,
} = calculationsApi;

export default calculationsApi;
